/* eslint-disable */


/**
 * @file Defines component that manages modals/pop-up forms renderings for user data creations
 *  and edits
 */

import React, { useState, useEffect, useReducer } from "react";
import axios from "axios";
import * as qs from 'qs'
import styled, { css } from "styled-components";
import Select from 'react-select';
import moment from "moment";
import { createGlobalStyle } from 'styled-components';
import Switch from "react-switch";
import TimeField from 'react-simple-timefield';
import * as json from './countries.json';

import {
  Margin,
  H3,
  H5,
  H6,
  Text14,
  Text12,
  Text11,
  Text10,
  FileUpload,
} from '../../atoms';


/**
 * @component Modal - Manages modals/pop-up forms renderings for user data creations
 *  and edits, depending on each pop-up information type and task
 * 
 * @param isOpen {bolean} - Defines if pop-up is being rendered
 * @param content ...
 * @param typecontent ...
 * ...
 *
 * CALLED BY:
 * - managementType.js 
 * 
 * BUGS:
 * @todo Doesn't allow blocks and slots languages definitions for times 09:00 and sooner
 *   @todo Solution: include initial 0 when comparing input
 * 
 * TO IMPROVE:
 * @todo Complete @param comments.
 * @todo Review and standardize variable names:
 *   @todo Make sure all internal variables are in english
 *   @todo Decide if database variables should be in English or Portuguese and standardize
 *   @todo Add descriptive comments to all potentially ambiguous variables
 * 
 */ 

export default function Modal(props) {

  const {
    isOpen,
    content,
    typeContent,    
    token,
    daysAvailable,
    seasonId,
    toogleModal,
    events,
    setEvents,    
    blocks,
    setBlocks,
    discounts,
    setDiscounts,
    days,
    setDays,
    reservations,
    setReservations,
    seasons,
    setSeasons,
    serviceId,
    userServices,
    idBilheteira,
    coupons,
    setCoupons,
    slotsLanguages,
    setSlotsLanguages,
    ticketTypes,
    setTicketTypes,
    languages,
    setLanguages,
    users,
    setUsers,
    user,
    setUser,
    setUserProfile,
    userProfile,
    dataService, 
    ticketOffice,
    setTicketOffice,
    handleUpload,
    payments,
    homeServices,
    setHomeServices,
    teams,
    setTeams,
    userObject,
    setUserObject,    
    BACKEND_DOMAIN,
  } = props;

/**
 * CONSTANTS AND VARIABLES
 *
 * TODO:
 * @todo Complete comments
 */

  let key = token;        //? Why not change to const?

  let initialState = [
    { value: 'segunda', label: 'segunda' },
    { value: 'terca', label: 'terca' },
    { value: 'quarta', label: 'quarta' },
    { value: 'quinta', label: 'quinta' },
    { value: 'sexta', label: 'sexta' },
    { value: 'sabado', label: 'sabado' },
    { value: 'domingo', label: 'domingo' }
  ];

  const availableLanguages = [                    // Available languages in databse and flags in static
    { value: 'PT', label: "PT" },                 // TO IMPROVE:
    { value: 'EN', label: "EN" },                 // - Replace this array by fetching data input by user
    { value: 'ES', label: "ES" },
    { value: 'FR', label: "FR" },
    { value: 'DE', label: "DE" },
    { value: 'IT', label: "IT" },
  ]

  const ticketVarieties = [                       // ...
    { value: 'Unique', label: 'Unique' },
    { value: 'Multiple', label: 'Multiple' },  
  ]


/**
 * STATE HOOKS
 *
 * TODO:
 * @todo Complete comments
 */

  const [inputs, setInputs] = useState({});                          // Modal/pop-up fields inputs
  const [slots, setSlots] = useState([]);                            // Time slots for selected date value/label pairs
  
  const [multiTicket, setMultiTicket] = useState(false)  
  const [availableTickets, setAvailableTickets] = useState([]);  
  const [ticketOptions, setTicketOptions] = useState([]);

  const [unitPrice, setUnitPrice] = useState(0);
  const [originalQuantity, setOriginalQuantity] = useState(0);       // Original quantity when editing reservation  
  const [reservationSeasonId, setReservationSeasonId] = useState(0);

  const [allSeasons, setAllSeasons] = useState([]);
  const [availableSeasons, setAvailableSeasons] = useState([])       // Available service seasons database property/value pairs
  const [editedSeason, setEditedSeason] = useState({
    DataInicio: '', 
    DataFim: '', 
  })  
  const [seasonOptions, setSeasonOptions] = useState([])             // Available service seasons value/label pairs for dropdown select element  
  const [dayWeek, setDayWeek] = useState(initialState);              // ...       
  
  const [allServices, setAllServices] = useState([]);                // Array with all services of bilheteira complete w/ all database records
  const [services, setServices] = useState([]);                      // Array with all active services
  const [servicesOptions, setServicesOptions] = useState([]);        // Array with all services of bilheteira value/label pairs
  const [selectedServices, setSelectedServices] = useState([]);      // Checked services for specific edited/added user  
  const [serviceIndex, setServiceIndex] = useState(null);            // Index of checked/unchecked service in user
  
  const [userId, setUserId] = useState(null)
  const [allUsers, setAllUsers] = useState([]);   
  const [usersOptions, setUsersOptions] = useState([]); 
  const [selectedUsers, setSelectedUsers] = useState([]);            // Checked users for specific edited/added user  
  const [userIndex, setUserIndex] = useState(null);                  // Index of checked/unchecked service in user  
  const [userProfiles, setUserProfiles] = useState([]);  
  const [profileOptions, setProfileOptions] = useState ([]);
  const [teamOptions, setTeamOptions] = useState ([])

  const [languageOptions, setLanguageOptions] = useState([])         // Available event languages value/label pairs for dropdown select element
  const [languagesStatus, setLanguagesStatus] = useState({})         // Status (enabled/disabled) of event languages for dropdown select element
  const [selectedLanguages, setSelectedLanguages] = useState([])     // Available events languages database property/value pairs
  const [editedSlotLanguage, setEditedSlotLanguage] = useState({
    date: '',
    slot: '',
  })
  const [countries, setCountries] = useState([]);

  const [aboutDiscount, setAboutDiscount] = useState([             // ...
    { value: 'num_bilhetes', label: 'num_bilhetes' },
    { value: 'geral', label: 'geral' }
  ]);

  const [comparator, setComparator] = useState([                   // ...
    { value: 'maior', label: 'maior' },
    { value: 'menor', label: 'menor' },
    { value: 'igual', label: 'igual' }
  ]);

  const [success, setSuccess] = useState(false);                   // ...
  const [error, setError] = useState(false);                       // ...
  const [errorMessage, setErrorMessage] = useState('')             // ...
  const [loading, setLoading] = useState(false);                   // ...
  const [submit, setSubmit] = useState(false);                     // ...


/**
 * OTHER HOOKS
 *
 * TODO:
 * @todo Complete comments
 */

  useEffect(( )=> {
    getUserId(user)

    if(typeContent === 'editUser') getTeamUsers(content.username)
    if(typeContent === 'editReservation' || typeContent === 'addReservation') {
      setOptionsCountries(json.default)
    }     
  }, [])

  useEffect(() => {
    console.log(typeContent)

    checkInputs()

    if(typeContent === 'editService') setServices(homeServices)
    if(typeContent === 'editSeason') saveEditedSeason()
    if(typeContent === 'editSlotLanguage') saveEditedSlotLanguage();
    if(typeContent === 'editSlotLanguage' ||
       typeContent === 'addSlotLanguage' ||
       typeContent === 'editSeason' ||
       typeContent === 'addSeason') getLanguagesStatus();
    if(typeContent === 'editUser' || typeContent === 'addUser'){
      getUserServices(content.id)
    }

    if (token !== undefined) {
      checkService()
      checkUser()
      checkSeasons()
      getUserProfiles()      
    }

    setTimeout(() => {
      setSuccess(false)
      setError(false)
      setLoading(false)
      setSubmit(false)
    }, 200)
  }, [isOpen])   

  useEffect(() => {   
    checkSlot()
  }, [inputs])

  useEffect(() => {
    getLanguages()
  }, [languagesStatus])

  useEffect(() => {
    if (typeContent == "editReservation") setMultiTicket(inputs.multiTicket)     
  }, [allServices]) 

  useEffect(() => {
    console.log(services)
    if(services.length != 0) setHomeServices(services)
  }, [services]) 

  useEffect(() => {
    if(typeContent === 'editTicketType' ||
      typeContent === 'addTicketType' ||
      typeContent === 'editReservation' ||
      typeContent === 'addReservation') checkTicketTypes()
  }, [isOpen, reservationSeasonId])

  useEffect(() => {
    changeServiceCheck()
  }, [serviceIndex])

  useEffect(() => {
    changeTeamUsersCheck()
  }, [userIndex])  

  useEffect(() => {
    checkProfiles()
  }, [userProfiles])

  useEffect(() => {
    checkTeams()
  }, [teams])

  useEffect(() => {
    initTypesQuantities()
  }, [availableTickets])


/**
 * HANDLERS / ? CALLBACKS
 *
 * TODO:
 * @todo Complete comments
 */

/**
 * @callback handleInputChange - Handles data change on input fields
 * 
 * @param event {synthetic event} - Event generated on change of input field
 * 
 * CALLED BY:
 * - On change of input fields
 */

  const handleInputChange = (event) => {
    console.log(inputs)
    console.log(event)
    console.log(typeContent)
    console.log(serviceId)

    event.persist()

    setInputs(inputs => ({ ...inputs, [event.target.name]: event.target.value }))

    if (typeContent == 'addEvent') setInputs(inputs => ({ ...inputs, servico: serviceId }))
  }

  const handleCountry = (data) => {
    setInputs(inputs => ({ ...inputs, country: data.label }))
  }  


/**
 * @callback handleTypeQuantityChange - Handles data change on input fields
 * 
 * @param event {synthetic event} - Event generated on change of input field
 * 
 * CALLED BY:
 * - 
 */

  const handleTypeQuantityChange = (event, index) => {
    console.log(inputs)
    console.log(event)

    event.persist();

    if (typeContent === 'editReservation') {
      setInputs(inputs => ({ ...inputs, typesReservation:updateTypesRes(event.target.value, index)}))
    } else if (typeContent === 'addReservation') {   
      setInputs(inputs => ({ ...inputs, typesQuantities:updateTypesQuantities(event.target.value, index)}))      
    }
  }  

/**
 * @function getUpdatedTypeRes - Handles data change on input fields
 * 
 * CALLED BY:
 * - 
 */
  const updateTypesRes = (typeQuantity, index) => {
    console.log(inputs.typesReservation)
    console.log(inputs.typesQuantities)

    inputs.typesQuantities[index].quantity = parseInt(typeQuantity)

    console.log(inputs.typesQuantities)

    const newTypesReservation = inputs.typesReservation

    newTypesReservation[newTypesReservation.findIndex(typeRes => typeRes.tipo_bilhete == inputs.typesQuantities[index].id)].quantidadeTipo = parseInt(typeQuantity)

    console.log(newTypesReservation)

    return newTypesReservation
  }

/**
 * @function updateTypesQuantities - Handles data change on input fields
 * 
 * CALLED BY:
 * - 
 */  
  const updateTypesQuantities = (typeQuantity, index) => {
    console.log(inputs.typesReservation)
    console.log(inputs.typesQuantities)

    if (inputs.typesQuantities[index] != undefined) inputs.typesQuantities[index].quantity = parseInt(typeQuantity)

    console.log(inputs.typesQuantities)

    return inputs.typesQuantities
  }

/**
 * @callback handleServiceCheck - Handles services checked boxes events
 *
 * CALLED BY:
 * - When one language checkbox is clicked
 *
 * TO IMPROVE:
 * - Replace index use by id for reference
 */
  const handleServiceCheck = (event) => {
    event.persist()

    console.log(event.target.value)
    console.log(allServices)
   
    const index = allServices.findIndex((service) => {
      return (service.nome === event.target.value)
    });

    console.log(index)

    setServiceIndex(index)
  }

/**
 * @callback handleTeamCheck - Handles services checked boxes events
 *
 * CALLED BY:
 * - When one language checkbox is clicked
 *
 * TO IMPROVE:
 * - Replace index use by id for reference
 */
  const handleTeamCheck = (event) => {
    event.persist()

    console.log(event.target.value)
    console.log(users)
   
    const index = allUsers.findIndex((usr) => {
      return (usr.username === event.target.value)
    });

    console.log(index)

    setUserIndex(index)
  }

/**
 * @callback handleServiceChange - ...
 *
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * - 
 */
  const handleServiceChange = (event) => {
    console.log(event)
    console.log(allServices)
    console.log(inputs.date)
     
    setInputs(inputs => ({ ...inputs, servico: event.value }))

    axios
      .get(BACKEND_DOMAIN + "/epocas", {
        headers: {
          Authorization: `Bearer ${key}`,
        },
      })
      .then(response => {
        console.log('SEASONS:')
        console.log(response.data)

        const serviceSeasons = response.data.filter(season => season.servico.id == event.value)

        console.log(serviceSeasons)

        if(inputs.date) {
          var dateSeason = serviceSeasons.filter(season => 
            moment(inputs.date).isSameOrAfter(moment(season.DataInicio)) &&
            moment(inputs.date).isSameOrBefore(moment(season.DataFim)))
        }

        console.log(dateSeason)

        if (dateSeason) {
          if (dateSeason[0].variedadeBilhetes == 'Multiple') {
            setMultiTicket(true)
          } else {
            setMultiTicket(false)
          }
        } else {
          setMultiTicket(false)
        }
      })
  }


/**
 * @callback handleDateServiceInputChanges - Handles date and service input changes
 *   for reservations creation and edition
 * 
 * @param event {synthetic event} - Event generated on change of input field
 * 
 * CALLED BY:
 * - On change of date and service in reservation creation and edition
 */

  useEffect ( () => {  
    console.log('GET SEASON')
    console.log(inputs.servico)
    console.log(inputs.date)

    if (inputs.servico !== ('' || undefined) && inputs.date !== ('' || undefined)) {
      getSeasonId(inputs.servico, inputs.date)
    }
  },[inputs.servico, inputs.date, inputs.time])


  let newSeasonId


  useEffect ( () => { 
      console.log(newSeasonId)
      setReservationSeasonId(newSeasonId);
      console.log('SEASON ID SET')    
  },[newSeasonId])  


  useEffect (() => {
    console.log(allSeasons)
    console.log(reservationSeasonId)

    if (reservationSeasonId != 0) {
      allSeasons.map(season => {
        if (season.id == reservationSeasonId) {
          console.log (season.PrecoUnitario)
          setUnitPrice(season.PrecoUnitario)
        }
      })
    }
  },[reservationSeasonId])



/**
 * FUNCTIONS
 *
 * TODO:
 * @todo ? Include setstates in the form of @state in function header comments?
 */

/**
 * @function checkInputs - Checks corresponding database fields values when modal is opened,
 *  and sets its values in the inputs state
 * 
 * CALLED BY:
 * - When modal is opened or closed  
 * 
 * TO IMPROVE:
 * @todo Review and standardize variable names:
 *   @todo Make sure all internal variables are in english
 *   @todo Decide if database variables should be in English or Portuguese and standardize
 *   @todo Add descriptive comments to all potentially ambiguous variables
 */ 
  const checkInputs = () => {
    console.log('CHECK INPUTS')
    console.log(content)

    let temp = {}

    if (typeContent === 'editBlock') { 
      temp = { 
        date: content.data,
        slot: content.slot,
        visivel:content.visivel, 
      };
    } else if (typeContent === 'editEvent') { 
      temp = {
        date: content.data,
        time: content.hora,
      };
    } else if (typeContent === 'editDiscount') { 
      temp = {
        about: content.Sobre,
        comparator: content.comparador,
        value: content.valor,
        discount: content.desconto,
      };
    } else if (typeContent === 'editDay') {
      setDayWeek(initialState)

      temp = { 
        dayweek: content.diaSemana,
        startHour: moment.utc(content.horaInicio, 'HH:mm').format('HH:mm'),
        endHour: moment.utc(content.horaFim, 'HH:mm').format('HH:mm'),
        tickets: content.numBilhetes, 
        active: content.ativo,
      }
    } else if (typeContent === 'editReservation') {
      temp = { 
        name: content.nome,
        email: content.email,
        country: content.pais,
        contact:content.contacto,
        vat: content.nif,
        obs: content.obs,
        quantity: content.quantidade,
        date: moment(content.data).utc().format('YYYY-MM-DD'),
        slot: moment(content.data).utc().format('HH:mm:ss.SSS'),
        servico: content.servico.id,
        multiTicket: content.multiTicket,         
        typesQuantities: content.quantidadesTipo,
        typesReservation: content.tiposreservas,
        sendInvoice: content.compra.faturaEnviada,
      }

      setOriginalQuantity(content.quantidade)
    } else if (typeContent === 'editCoupon') {
      temp = { 
        name: content.nome,
        code: content.codigo, 
        discount: content.desconto, 
        startDate: content.dataInicio, 
        endDate: content.dataFim, 
        utilizations: content.utilizacoes, 
      };
    } else if (typeContent === 'editSeason') {
      temp = { 
        Text: content.Text, 
        DataInicio: content.DataInicio, 
        DataFim: content.DataFim, 
        ticketVariety: content.variedadeBilhetes, 
        PrecoUnitario: content.PrecoUnitario, 
        DiscountPrice: content.PrecoDesconto, 
        DiscountPercentage: content.PercentagemDesconto, 
        DiscountQuantity: content.QuantidadeDesconto,                
        language: content.lingua,
      };
    } else if (typeContent === 'editTicketOffice') {
      temp = { 
        title: content.name, 
        description: content.description, 
        url: content.url, 
        email: content.email, 
        Pagamentos: content.Pagamentos, 
        stripe_api_key: content.stripe_api_key,
      }
    } else if (typeContent === 'editLanguage') {
      temp = { 
        nomeLingua_PT: content.nomeLingua_PT, 
        nomeLingua_EN: content.nomeLingua_EN, 
        Codigo: content.Codigo, 
        Lingua_Disponivel: content.Lingua_Disponivel,
      }
    } else if (typeContent === 'editSlotLanguage') {
      temp = { 
        date: content.Data, 
        slot: content.slot, 
        language: content.lingua,
      }
    } else if (typeContent === 'editTicketType') {
      temp = { 
        ticketTypePT: content.tipoBilhetePT,
        ticketTypeEN: content.tipoBilheteEN, 
        ticketTypeFR: content.tipoBilheteFR, 
        ticketPrice: content.precoBilhete, 
        season: content.epoca, 
        activeTicketType: content.tipoBilheteAtivo};           
    } else if (typeContent === 'editService') {
      temp = { 
        serviceName: content.nome,
        serviceNameEN: content.nomeEN, 
        serviceNameFR: content.nomeFR, 
        description: content.descricao,     
        descriptionEN: content.descricaoEN, 
        descriptionFR: content.descricaoFR,
        checkinTextPT: content.checkinTextPT,     
        checkinTextEN: content.checkinTextEN, 
        checkinTextFR: content.checkinTextFR,   
        checkoutTextPT: content.checkoutTextPT,     
        checkoutTextEN: content.checkoutTextEN, 
        checkoutTextFR: content.checkoutTextFR,         
        urlPath: content.url, 
        serviceImage: content.imagemEvento, 
        duration:content.duracao, 
        break: content.intervalo, 
        limitHour: content.horaLimite,
        timeAdvance: content.timeAdvance,
        timeLimitOverride: content.timeLimitOverride,
        timeAdvanceType: content.timeAdvanceType,
        activeService: content.ativo,
        backupEmailService: content.backupEmailServico,
        payments: content.pagamentos,
        invoicing: content.faturacao,
        urlDomain: content.urlDomain,
        urlInfo: content.urlInfo,
      }
    } else if (typeContent === 'editUser') {
      //console.log(userServices)

      temp = { 
        userName: content.username,
        password: content.password,
        email: content.email, 
        userProfile: content.user_profile,
        services: userServices,
        confirmed: content.confirmed,
        active: content.active,
        team: content.team,
      }                               
    } else if (typeContent === 'addBlock') {
      temp = { 
        date: '', 
        slot: '', 
        visivel: false,
      }
    } else if (typeContent === 'addEvent') {
      temp = { 
        date: '', 
        time: '00:00', 
      }
    } else if (typeContent === 'bulkBlock') {
      temp = { 
        dataInicio: '', 
        dataFim: '', 
        visivel: false, 
      }
    } else if (typeContent === 'rangeEvents') {
      temp = { 
        dataInicio: '', 
        dataFim: '', 
        time: '00:00', 
      }
    } else if (typeContent === 'addDiscount') {
      temp = { 
        about: '', 
        comparator: '', 
        value: '', 
        discount: '',
      }
    } else if (typeContent === 'addDay' || typeContent === 'bulkDay') {
      setDayWeek(daysAvailable)

      temp = { 
        dayweek: '', 
        startHour: '', 
        endHour: '', 
        tickets: '',
        active: 'true',
      }
    } else if (typeContent === 'addReservation') {
      temp = { 
        name: '', 
        email: '', 
        country: 'Portugal',
        contact:'', 
        vat: '', 
        obs: '', 
        quantity: '', 
        date: '', 
        slot: '', 
        servico: '',
        multiTicket: false, 
        typesQuantities: [ ],
        typesReservation: [ ],
        sendInvoice: false,
      }
    } else if (typeContent === 'addCoupon') {
      temp = { 
        name: '', 
        code: '', 
        discount: '', 
        startDate: '', 
        endDate: '', 
        utilizations: '',
      }
    } else if (typeContent === 'addSeason') {
      temp = { 
        Text: '', 
        DataInicio: '', 
        DataFim: '', 
        ticketVariety: '', 
        PrecoUnitario: '', 
        DiscountPrice: '', 
        DiscountPercentage: '', 
        DiscountQuantity: '',          
        language: null,
      }
    } else if (typeContent === 'addLanguage') {
      temp = { 
        nomeLingua_PT: '', 
        nomeLingua_EN: '', 
        Codigo: '', 
        Lingua_Disponivel: '', 
      }
    } else if (typeContent === 'addSlotLanguage') {
      temp = { 
        date: '', 
        slot: '', 
        language: '',
      }
    } else if (typeContent === 'addTicketType') {
      temp = {  
        ticketTypePT: '', 
        ticketTypeEN: '', 
        ticketTypeFR: '',
        ticketPrice: '', 
        season:'', 
        activeTicketType: ''
      }                                   
    } else if (typeContent === 'addService') {
      temp = { 
        serviceName: '',
        serviceNameEN: '', 
        serviceNameFR: '', 
        description: '',     
        descriptionEN: '', 
        descriptionFR: '', 
        checkinTextPT: '',     
        checkinTextEN: '', 
        checkinTextFR: '',   
        checkoutTextPT: '',     
        checkoutTextEN: '', 
        checkoutTextFR: '',                           
        urlPath: '', 
        serviceImage:'', 
        duration:'', 
        break:'', 
        limitHour:'',
        timeAdvance: '',
        timeLimitOverride: '',
        timeAdvanceType: '',       
        activeService: false,
        backupEmailService: '',
        payments: false, 
        invoicing: false,
        urlDomain: '',
        urlInfo: '',      
      }
    } else if (typeContent === 'addUser') {
      temp = { 
        userName: '',
        password: '',
        email: '',
        userProfile: {},
        services: [],         
        confirmed: false,
        active: false,
        team: {},
      }                 
    }

    setInputs(temp)
  }


/**
 * @function setOptionsCountries - ...
 *
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * -
 */  
  const setOptionsCountries = (data) => {
    let dataCountries = data
    let dataFiltred = []
    let initialCountry = 'Portugal'
    let initialCode = '+351'

    for (let i = 0; i < dataCountries.length; i++) {
      dataFiltred.push({ value: dataCountries[i].alpha2Code, label: dataCountries[i].name, code: dataCountries[i].callingCodes[0] });
    }

    /* for invoiceXpress country must be always in english
      if (lang == 'pt') {
        initialCountry = 'United Kingdom of Great Britain and Northern Ireland';
        initialCode = '+44';
        for (let i = 0; i < dataCountries.length; i++) {
          dataFiltred.push({ value: dataCountries[i].alpha2Code, label: dataCountries[i].translations.pt, code: dataCountries[i].callingCodes[0] });
        }
      if (lang == 'fr') {
        initialCountry = 'France';
        initialCode = '+33';
        for (let i = 0; i < dataCountries.length; i++) {
          dataFiltred.push({ value: dataCountries[i].alpha2Code, label: dataCountries[i].translations.fr, code: dataCountries[i].callingCodes[0] });
        }
      }
    }*/

    setCountries(dataFiltred)
    //setInputs(inputs => ({ ...inputs, country: initialCountry}))
    console.log('TRACK')
  }


/**
 * @function checkSlot - Defines slots times for selected date and stores them in slots state.
 * 
 * @param content ...
 * @param setSlots ...
 * ...
 * 
 * CALLED BY:
 * - When there are inputs changes 
 * 
 * TO IMPROVE:
 * @todo Complete function comments
 * @todo Reduce by creating external function for .then
 */
  const checkSlot = () => {
    if (typeContent == 'editBlock' || typeContent === 'addBlock') {
      if (inputs.date) {
        axios.post(BACKEND_DOMAIN + "/servicos/" + serviceId + '/disponibilidade/dia', {
          data: moment(inputs.date).format('YYYY-MM-DD') }, {
          headers: 
            {
              Authorization: `Bearer ${key}`,
            },
          }
        )
          .then(function (response) {
            console.log(response.data)

            let options = []

            for (let i = 0; i < response.data.slots.length; i++) {
              options.push(
                {
                  value: response.data.slots[i].slot + ":00.000",
                  label: response.data.slots[i].slot
                }
              )
            }
            setSlots(options);
          })
          .catch(function (error) {
          })
      }    
    } else if (typeContent == 'editSlotLanguage' || typeContent === 'addSlotLanguage') {
      if (inputs.date) {
        axios.post(BACKEND_DOMAIN + "/servicos/" + serviceId + '/disponibilidade/dia', {
          data: moment(inputs.date).format('YYYY-MM-DD') }, {
          headers: {
            Authorization: `Bearer ${key}`,
          },
        })
          .then(function (response) {
            console.log(response.data)

            let options = [];
            for (let i = 0; i < response.data.slots.length; i++) {

              options.push(
                {
                  value: response.data.slots[i].slot + ":00.000",
                  label: response.data.slots[i].slot
                }
              )
            }
            setSlots(options);
          })
          .catch(function (error) {
          })
      }
    } else if (typeContent === 'addReservation' || typeContent === 'editReservation') {
      console.log(inputs)
      console.log(content)

      if (typeContent === 'editReservation') {
        if (content?.servico?.id && inputs.date) {
          axios.post(BACKEND_DOMAIN + "/servicos/" + content.servico.id + '/disponibilidade/dia' + '?_limit=-1', {
            data: moment(inputs.date).format('YYYY-MM-DD') 
          }, {
            headers: {
              Authorization: `Bearer ${key}`,
            }
          })
            .then(function (response) {
              console.log(response)

              let options = []

              for (let i = 0; i < response.data.slots.length; i++) {
                if (response.data.slots[i].tickets > 0 &&
                  inputs.quantity <= response.data.slots[i].tickets + originalQuantity) {
                    options.push(
                      {
                        value: response.data.slots[i].slot + ":00.000",
                        label: response.data.slots[i].slot
                      }
                    )
                }
                console.log(response.data.slots[i].slot)
                console.log(inputs.slot)
                console.log(response.data.slots[i].tickets)                 
                console.log(inputs.quantity)                            

                if (inputs.slot == response.data.slots[i].slot &&
                  inputs.quantity > response.data.slots[i].tickets + originalQuantity) {
                    console.log("'INPUTS SLOT= ''")

                    inputs.slot = ''
                }
              }

              console.log(options)

              setSlots(options)
            })
            .catch(function (error) {
            })
        }
      } else {
        if (inputs.servico && inputs.date) {
          axios.post(BACKEND_DOMAIN + "/servicos/" + inputs.servico + '/disponibilidade/dia' + '?_limit=-1', {
            data: moment(inputs.date).format('YYYY-MM-DD') 
          }, {
            headers: {
              Authorization: `Bearer ${key}`,
            }
          }
          )
          .then(function (response) {
            let options = [];
            for (let i = 0; i < response.data.slots.length; i++) {
              if (response.data.slots[i].tickets > 0 && inputs.quantity <= response.data.slots[i].tickets) {
                options.push(
                  {
                    value: response.data.slots[i].slot + ":00",
                    label: response.data.slots[i].slot
                  }
                )
              }
            }

            console.log(options)

            setSlots(options);
          })
          .catch(function (error) {
          })
        }
      }
    }
  }


/**
 * @function getUserId - 
 * 
 * TO IMPROVE:
 * ...
 * 
 */
  const getUserId = (user) => {
    axios
      .get(BACKEND_DOMAIN + '/users?username=' + user, {
        headers: {
          Authorization: `Bearer ${key}`,
        },
      })
      .then(response => {
        console.log(response.data)
        
        if (response.data.length !== 0) setUserId(response.data[0].id)
    })
  }


/**
 * @function getLanguagesStatus - Gets languages status from bilheteira (enabled/disabled).
 * 
 * TO IMPROVE:
 * ...
 * 
 */
  const getLanguagesStatus = () => {
    axios
      .get(BACKEND_DOMAIN + '/configuracoes?bilheteira=' + idBilheteira, {
        headers: {
          Authorization: `Bearer ${key}`,
        },
      })
      .then(response => {
        console.log(response.data)
        
        if (response.data.length !== 0) {
          setLanguagesStatus ({
            EN: response.data[0].ingles,
            PT: response.data[0].portugues,
            FR: response.data[0].frances,
            ES: response.data[0].espanhol,
            IT: response.data[0].italiano,
            DE: response.data[0].alemao,
          })
        }
    })
  }


/**
 * @function getLanguages - Gets available languages from database.
 * 
 * TO IMPROVE:
 * @todo Check ideal value/labels fields.
 * @todo Check possibility to refactor output into one unique state 
 *   (setLanguageOption + setSelectedLanguages)
 */
  const getLanguages = () => {
    console.log(languagesStatus)

    setLoading(true);

    axios
      .get(BACKEND_DOMAIN + "/linguas", {
        headers: {
          Authorization: `Bearer ${key}`,
        },
      })
      .then(function (response) {
        console.log(response.data);

        let options = [];
        let newSelectedLanguages = [];
        let langStatus = Object.keys(languagesStatus)

        console.log(langStatus)

        response.data.map(lang => {
          console.log(lang)

          langStatus.map(status =>{
            if (lang.Codigo == status && languagesStatus[status]) {
              console.log(status)
              options.push({
                  value: lang.Codigo,
                  label: lang.nomeLingua_EN,
              }); 
              newSelectedLanguages.push(lang);             
            }
          }) 
        })

        options.unshift({
            value: null,
            label: '',
        });

        console.log(options)

        setLanguageOptions(options);
        setSelectedLanguages(newSelectedLanguages);
      })
      .catch(function (error) {
      });
  }

/**
 * @function checkSeasons - Saves available seasons from database into application state.
 * 
 * TO IMPROVE:
 * @todo Check ideal value/labels fields.
 * @todo Check possibility to refactor output into one unique state
 */
  const checkSeasons = () => {
    setLoading(true);

    axios
      .get(BACKEND_DOMAIN + "/epocas", {
        headers: {
          Authorization: `Bearer ${key}`,
        },
      })
      .then(function (response) {
        let options = [];
        let newAvailableSeasons = [];

        console.log(response.data);
        console.log(serviceId)

        try {
          for (let i = 0; i < response.data.length; i++) {
            if (response.data[i].servico && response.data[i].servico.id == serviceId) {
              console.log(response.data[i])
              options.push(
                {
                    value: response.data[i].Text,
                    label: response.data[i].Text,
                }
              );
              newAvailableSeasons.push(response.data[i]);
            }
          }
        } catch (error) {
          console.error('Error in loop:', error);
        }
        
        console.log(options)

        setAllSeasons(response.data);
        setSeasonOptions(options);
        setAvailableSeasons(newAvailableSeasons);

        console.log('SEASONS CHECKED')
      })
      .catch(function (error) {
      });
  }

/**
 * @function getUserProfiles
 * 
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * - 
 */ 
  const getUserProfiles = () => {
    setLoading(true);
    axios
      .get(BACKEND_DOMAIN + "/user-profiles" + '?_limit=-1', {
        headers: {
          Authorization: `Bearer ${key}`,
        },
      })
      .then(response => {
        console.log(response.data)

        let tempArr = response.data

        tempArr.sort(function (a, b) {
          if (a.created_at > b.created_at) {
            return -1
          }
          if (a.created_at < b.created_at) {
            return 1;
          }
          return 0;
        })

        console.log(tempArr)

        setUserProfiles(tempArr)

        setLoading(false)
      })
      .catch(error => {
        console.log(error)

        setLoading(false)
      });
  };


/**
 * @function checkProfiles - Saves user profiles from database into application state.
 * 
 * TO IMPROVE:
 * @todo Check ideal value/labels fields.
 * @todo Check possibility to refactor output into one unique state
 */
  const checkProfiles = () => {
    setLoading(true)

    console.log(userProfile)
    console.log(userProfiles)

    let options = [];
    let newProfiles = [];

    for (let i = 0; i < userProfiles.length; i++) {
      if (userProfile == 'Administrator' ||
        (userProfile == 'Manager' 
          && (userProfiles[i].permissionsLevel != 'Administrator' && userProfiles[i].permissionsLevel != 'Manager'))) {
            options.push(
              {
                value: userProfiles[i].permissionsLevel,
                label: userProfiles[i].permissionsLevel,
              }
            )
      }
    };

    console.log(options)

    setProfileOptions(options);

    console.log('PROFILES CHECKED')
  }

/**
 * @function checkTeams - 
 * 
 * TO IMPROVE:
 * @todo Check ideal value/labels fields.
 * @todo Check possibility to refactor output into one unique state
 */
  const checkTeams = () => {
    setLoading(true)

    //console.log(team)
    if (teams != undefined) {
      console.log(teams)

      let options = [];
      let newTeam = [];

      for (let i = 0; i < teams.length; i++) {      
        options.push(
          {
            value: teams[i].manager,
            label: teams[i].manager,
          }
        )
      }

      console.log(options)

      setTeamOptions(options);

      console.log('TEAMS CHECKED')
    }
  }

/**
 * @function checkTicketTypes - Saves available ticket types from database into application state.
 * 
 * TO IMPROVE:
 * @todo Check ideal value/labels fields.
 * @todo Check possibility to refactor output into one unique state
 */
  const checkTicketTypes = () => {
    console.log('CHECK TICKET TYPES:')
    if (reservationSeasonId !== undefined) {
      setLoading(true);

      axios
        .get(BACKEND_DOMAIN + "/tipo-bilhetes", {epoca: reservationSeasonId}, {
          headers: {
            Authorization: `Bearer ${key}`,
          },
        })
        .then(function (response) {
          let options = []
          let newAvailableTickets = []

          console.log('TICKET TYPES')
          console.log(response.data)

          for (let i = 0; i < response.data.length; i++) {
            console.log('TICKET TYPES OPTIONS')

            if (response.data[i].epoca.id == reservationSeasonId) {
              options.push(
                {
                    value: response.data[i].tipoBilheteEN,
                    label: response.data[i].tipoBilheteEN,
                }
              )
              newAvailableTickets.push(response.data[i]);
            }
          }

          console.log(options)
          console.log(newAvailableTickets)

          setTicketOptions(options)
          setAvailableTickets(newAvailableTickets)
        })
      .catch(function (error) {
      })
    }
  }  

/**
 * @function initTicketQuantities - ...
 * 
 * TO IMPROVE:
 * 
 */
  const initTypesQuantities = () => {
    console.log('INIT TYPES QUANTITIES')
    console.log(availableTickets)
    console.log(inputs.typesReservation)

    if (typeContent != undefined) {
      var typesQuantitiesInit = []

      if (typeContent == 'addReservation') { 
        typesQuantitiesInit = availableTickets.map(type => (
          {
            id: type.id,
            label: type.tipoBilheteEN,
            quantity: 0,
          }
        ))
      } else if (typeContent == 'editReservation'){
        typesQuantitiesInit = availableTickets.map(type => (        
          {
            id: type.id,
            label: type.tipoBilheteEN,
            quantity: inputs.typesReservation[inputs.typesReservation.findIndex(typeRes => typeRes.tipo_bilhete == type.id)] != undefined
                      ? inputs.typesReservation[inputs.typesReservation.findIndex(typeRes => typeRes.tipo_bilhete == type.id)].quantidadeTipo
                      : null,
          }
        ))
      }

      console.log(typesQuantitiesInit)

      setInputs(inputs => ({...inputs, typesQuantities: typesQuantitiesInit}))

      console.log(inputs.typesQuantities)    
    }
  }


/**
 * @function getLanguage - Returns database language data from selectedLanguages array, based on
 *   dropdown field selected language option.
 *
 * @param - value {string} - Selected language from dropdown field.
 * 
 * CALLED BY:
 * - When dropdown language field is changed.
 */
  const getLanguage = (label) => {
    console.log(label)
    console.log(selectedLanguages)

    const language = selectedLanguages.find((lang) => {
        return (lang.nomeLingua_EN === label)
    })

    console.log(language)

    if (language == undefined) return null
      
    return (language)
  }


/**
 * @function getSeason - Returns database season data from availableSeasons array, based on
 *   dropdown field selected season option.
 *
 * @param - value {string} - Selected season from dropdown field.
 * 
 * CALLED BY:
 * - When dropdown season field is changed.
 */
  const getSeason = (value) => {
    const seasonIndex = availableSeasons.findIndex((season) => {
        return (season.Text === value)
    });

    console.log(availableSeasons[seasonIndex]);
    return (availableSeasons[seasonIndex]);
  }


/**
 * @function getProfile - Returns database ser profile data from userProfiles array, based on
 *   dropdown field selected userProfile option.
 *
 * @param - value {string} - Selected season from dropdown field.
 * 
 * CALLED BY:
 * - When dropdown season field is changed.
 */
  const getProfile = (value) => {
    console.log(userProfiles)

    const profileIndex = userProfiles.findIndex((profile) => {
        return (profile.permissionsLevel === value)
    });

    console.log(userProfiles[profileIndex])

    return (userProfiles[profileIndex])
  }


/**
 * @function getTeam - Returns database team data from teams array, based on
 *   dropdown field selected team option.
 *
 * @param - value {string} - Selected season from dropdown field.
 * 
 * CALLED BY:
 * - When dropdown season field is changed.
 */
  const getTeam = (value) => {
    console.log(value)
    console.log(teams)

    const teamIndex = teams.findIndex((team) => {
      return (team.manager === value)
    });

    console.log(teams[teamIndex])

    return (teams[teamIndex])
  }  


/**
 * @function getSeasonId - Returns season id based on calendar day and
 *   service inputs selected in modal.
 *
 * @param - value {string} - Selected season from dropdown field.
 * 
 * CALLED BY:
 * - When dropdown season field is changed.
 */
  const getSeasonId = async (service, date) => {
    console.log(date)
    console.log(service)

    if (service !== 0 && service !== undefined && date !== '') {
      console.log('GET SEASONID')

      await axios
        .post(BACKEND_DOMAIN + '/epocas/desconto', {
          servico: service,
          data: moment(date).format('YYYY-MM-DD')
        },{
          headers: {
            Authorization: `Bearer ${key}`,
          },
        })
        .then(function (response) {
          console.log(response.data.epocaId)
          console.log(seasonId)

          setReservationSeasonId(response.data.epocaId)
        })
        .catch(function (error) {
        })
    }
  }


/**
 * @function getTicketType - Returns database ticket types data from ticketOptions array, based on
 *   dropdown field selected language option.
 *
 * @param - value {string} - Selected language from dropdown field.
 * 
 * CALLED BY:
 * - When dropdown language field is changed.
 */
  const getTicketType = (value) => {
    console.log(value)
    console.log(availableTickets)
    console.log(isNaN(value))

    const ticketTypeIndex = availableTickets.findIndex((ticket) => {
        if (isNaN(value)) {
          return (ticket.tipoBilheteEN == value)
        } else {
          return (ticket.id === value)          
        }
    });

    console.log(ticketTypeIndex);    

    console.log(availableTickets[ticketTypeIndex]);

    if (availableTickets[ticketTypeIndex] != undefined && isNaN(value)) {
      return (availableTickets[ticketTypeIndex].tipoBilheteEN)
    } else if (availableTickets[ticketTypeIndex] != undefined) {
      return (availableTickets[ticketTypeIndex].tipoBilheteEN)          
    }    
  }


/**
 * @function closeModal - Closes modal/pop-up
 * 
 * CALLED BY:
 * - When 'Cancel' button in the modal is clicked
 */

  const closeModal = () => {
    toogleModal();
    setInputs({});
  }


/**
 * @function addsTeamUsers - 
 * 
 * CALLED BY:
 * - 
 */
  const addsTeamUsers = (newTeam) => {
    console.log('ADD USERS TO TEAM')
    console.log(content.team)
    console.log(newTeam)
    console.log(selectedUsers)

    let teamId = newTeam ? newTeam.id
      : inputs.team && Object.keys(inputs.team).length !== 0 ? inputs.team.id 
      : content.team && Object.keys(content.team).length !== 0 ? content.team.id
      : selectedUsers[0].team

    console.log(teamId)

    axios
      .put(BACKEND_DOMAIN + "/teams/" + teamId,{
        users: selectedUsers && newTeam ? selectedUsers.concat(newTeam.users)
          : selectedUsers ? selectedUsers
          : newTeam ? newTeam.users
          : content.team.users
      }, {
        headers: {
          Authorization: `Bearer ${key}`,
        },
      })
      .then(response => {
        console.log('TEAM USERS ADDED:')
        console.log(response.data)

        setSuccess(true)
        setError(false)
        setLoading(false)
        setTeams(teams.map(x => {
          if (x.id !== teamId) return x
          return {
            ...x,
            users: response.data.users
          }
        }))

        setTimeout(() => {
          toogleModal()
        }, 1500)
      })
      .catch(error => {
        setSuccess(false)
        setError(true)
        setLoading(false)
        setTimeout(() => {
          toogleModal()
        }, 1500)
      })    
  }

/**
 * @function addRow - Adds rows to each database collection and updates respective application state
 * 
 * @param - typeContent {string} - Defines collection to change and action to take within database
 *          and state
 * 
 * TO IMPROVE:
 * @todo ? Pass this function to separate file? As a component?
 */
  const addRow = async () => {
    console.log('ADDING ROW')
    console.log(inputs)

    setLoading(true)
    setSubmit(true)
    
    if (typeContent === 'addService') {
      console.log(idBilheteira)
      axios
        .post(BACKEND_DOMAIN + "/servicos/", {
          nome: inputs.serviceName,
          nomeEN: inputs.serviceNameEN,
          nomeFR: inputs.serviceNameFR,  
          descricao: inputs.description, 
          descricaoEN: inputs.descriptionEN, 
          descricaoFR: inputs.descriptionFR,
          checkinTextPT: inputs.checkinTextPT,     
          checkinTextEN: inputs.checkinTextEN, 
          checkinTextFR: inputs.checkinTextFR,   
          checkoutTextPT: inputs.checkoutTextPT,     
          checkoutTextEN: inputs.checkoutTextEN, 
          checkoutTextFR: inputs.checkoutTextFR,                                                            
          url: inputs.urlPath, 
          imagemEvento: inputs.serviceImage, 
          horaLimite: inputs.limitHour ? inputs.limitHour.includes('.000') ? inputs.limitHour : inputs.limitHour.length === 8 ? inputs.limitHour + '.000' :  inputs.limitHour + ':00.000' : '', 
          timeAdvance: inputs.timeAdvance,
          timeLimitOverride: inputs.timeLimitOverride,
          timeAdvanceType: inputs.timeAdvanceType,
          ativo: inputs.activeService,
          bilheteira: idBilheteira,
          backupEmailServico: inputs.backupEmailService, 
          pagamentos: inputs.payments, 
          faturacao: inputs.invoicing, 
          urlDomain: inputs.urlDomain,
          urlInfo: inputs.urlInfo,       
        }, {
          headers: {
            Authorization: `Bearer ${key}`,
          },
        }
        )
        .then(response => {
          console.log(response.data)

          setSuccess(true)
          setError(false)
          setLoading(false)

          console.log(services)
          services.push(response.data)
          console.log(services)

          setServices(services)

          setTimeout(() => {
            toogleModal()
          }, 1500)

          setInputs({})
        })
        .catch(error => {
          setSuccess(false)
          setError(true)
          setLoading(false)

          setTimeout(() => {
            toogleModal()
          }, 1500)

          setInputs({})
        }); 
    } else if (typeContent === 'addBlock') {
      axios
        .post(BACKEND_DOMAIN + "/bloqueios", {
          data: inputs.date,
          slot: inputs.slot == '' ? undefined : inputs.slot,
          servico: serviceId,
          visivel: inputs.visivel,
        }, {
          headers: {
            Authorization: `Bearer ${key}`,
          },
        }
        )
        .then(response => {
          setSuccess(true);
          setError(false);
          setLoading(false);
          blocks.push(response.data);
          let tempArr = blocks;
          tempArr.sort(function (a, b) {
            if (a.data > b.data) {
              return -1;
            }
            if (a.data < b.data) {
              return 1;
            }
            return 0;
          });
          setBlocks(tempArr);
          setTimeout(() => {
            toogleModal();
          }, 1500);
          setInputs({});
        })
        .catch(error => {
          setSuccess(false);
          setError(true);
          setLoading(false);
          setTimeout(() => {
            toogleModal();
          }, 1500);
          setInputs({});
        });
    } else if (typeContent === 'addEvent') {
      console.log(inputs.time)

      axios
        .post(BACKEND_DOMAIN + "/slots", {
          data: inputs.date,
          hora: inputs.time ? inputs.time.includes('.000') ? inputs.time : inputs.time.length === 8 ? inputs.time + '.000' :  inputs.time + ':00.000' : '',
          epoca: reservationSeasonId,
          servico: serviceId,
        }, {
          headers: {
            Authorization: `Bearer ${key}`,
          },
        })
        .then(response => {
          setSuccess(true);
          setError(false);
          setLoading(false);

          events.push(response.data);

          let tempArr = events;

          tempArr.sort(function (a, b) {
            if (a.data > b.data) {
              return -1;
            }
            if (a.data < b.data) {
              return 1;
            }
            return 0;
          });

          setEvents(tempArr);

          setTimeout(() => {
            toogleModal();
          }, 1500);

          setInputs({});
        })
        .catch(error => {
          setSuccess(false);
          setError(true);
          setLoading(false);

          setTimeout(() => {
            toogleModal();
          }, 1500);

          setInputs({});
        })    
    } else if (typeContent === 'addSlotLanguage') {
      try {
        for (let i = 0; i <= slotsLanguages.length - 1; i++) {

          if (slotsLanguages[i].Data == inputs.date &&
            slotsLanguages[i].slot == inputs.slot) {
            console.log('THROW ERROR')
            throw error;
          }
        }

        console.log(inputs)

        axios
          .post(BACKEND_DOMAIN + "/lingua-slots", {
            Data: inputs.date,
            slot: inputs.slot == '' ? undefined : inputs.slot,
            lingua: inputs.language,
            servico: serviceId
          }, {
            headers: {
              Authorization: `Bearer ${key}`,
            },
          }
          )
          .then(response => {
            setSuccess(true);
            setError(false);
            setLoading(false);
            console.log(response.data);
            slotsLanguages.push(response.data);
            let tempArr = slotsLanguages;
            tempArr.sort(function (a, b) {
              if (a.Data > b.Data) {
                return -1;
              }
              if (a.Data < b.Data) {
                return 1;
              }
              return 0;
            });
            setSlotsLanguages(tempArr);
            setTimeout(() => {
              toogleModal();
            }, 1500);
            setInputs({});
          })
          .catch(error => {
            setSuccess(false);
            setError(true);
            setLoading(false);
            setTimeout(() => {
              toogleModal();
            }, 1500);
            setInputs({});
          });              
      } catch (error) {
          setSuccess(false);
          setError(true);
          setLoading(false);
          setTimeout(() => {
            toogleModal();
          }, 1500);
          setInputs({});
      }  
    } else if (typeContent === 'addDiscount') {
      axios
        .post(BACKEND_DOMAIN + "/descontos", {
          Sobre: inputs.about,
          comparador: inputs.comparator,
          valor: inputs.value,
          desconto: inputs.discount,
          epoca: seasonId
        }, {
          headers: {
            Authorization: `Bearer ${key}`,
          },
        }
        )
        .then(response => {
          setSuccess(true);
          setError(false);
          setLoading(false);
          discounts.push(response.data)
          setDiscounts(discounts);
          setTimeout(() => {
            toogleModal();
          }, 1500);
          setInputs({});
        })
        .catch(error => {
          setSuccess(false);
          setError(true);
          setLoading(false);
          setTimeout(() => {
            toogleModal();
          }, 1500);
          setInputs({});
        });
    } else if (typeContent === 'addDay') {
      axios
        .post(BACKEND_DOMAIN + "/dias", {
          diaSemana: inputs.dayweek,
          horaInicio: inputs.startHour + ':00.000',
          horaFim: inputs.endHour + ':00.000',
          numBilhetes: inputs.tickets,
          ativo: inputs.active,
          epoca: seasonId
        }, {
          headers: {
            Authorization: `Bearer ${key}`,
          },
        }
        )
        .then(response => {
          setSuccess(true);
          setError(false);
          setLoading(false);
          days.push(response.data);
          setDays(days);
          setTimeout(() => {
            toogleModal();
          }, 1500);
          setInputs({});
        })
        .catch(error => {
          setSuccess(false);
          setError(true);
          setLoading(false);
          setTimeout(() => {
            toogleModal();
          }, 1500);
          setInputs({});
        });
    } else if (typeContent === 'addCoupon') {
      axios
        .post(BACKEND_DOMAIN + "/codigos", {
          nome: inputs.name,
          codigo: inputs.code,
          desconto: inputs.discount,
          ativo: true,
          dataInicio: inputs.startDate,
          dataFim: inputs.endDate,
          utilizacoes: inputs.utilizations,
          servico: serviceId
        }, {
          headers: {
            Authorization: `Bearer ${key}`,
          },
        }
        )
        .then(response => {
          setSuccess(true);
          setError(false);
          setLoading(false);
          coupons.push(response.data);
          setCoupons(coupons);
          setTimeout(() => {
            toogleModal();
          }, 1500);
          setInputs({});
        })
        .catch(error => {
          setSuccess(false);
          setError(true);
          setLoading(false);
          setTimeout(() => {
            toogleModal();
          }, 1500);
          setInputs({});
        });
    } else if (typeContent === 'addReservation') {

      console.log(allSeasons) 
      console.log(inputs)
      console.log(user)

      axios
        .post(BACKEND_DOMAIN + "/reservas", {
          servico: inputs.servico,          
          epoca: reservationSeasonId,
          data: inputs.date + 'T' + (inputs.slot.length === 7 ? '0'+inputs.slot+'.000Z' : inputs.slot+'.000Z'),   
          nome: inputs.name,
          email: inputs.email,
          pais: inputs.country,
          contacto:inputs.contact,
          nif: inputs.vat,
          obs: inputs.obs,
          quantidade: inputs.quantity != '' ? inputs.quantity : inputs.typesQuantities.reduce((acc,cval) => acc + cval.quantity, 0),
          quantidadeTipo: inputs.quantity,
          quantidadesTipos: inputs.typesQuantities,
          tiposreservas: inputs.typesReservation,            
          valor: inputs.quantity != '' ? unitPrice : '',
          precoTotal: inputs.quantity != '' ? unitPrice * inputs.quantity : inputs.typesQuantities.reduce((acc,cval) => {
              console.log(acc + cval.quantity * availableTickets[availableTickets.findIndex(type => type.id == cval.id)].precoBilhete)

              return  acc + cval.quantity * availableTickets[availableTickets.findIndex(type => type.id == cval.id)].precoBilhete
            },0),
          numTiposBilhete: inputs.typesQuantities.length != 0 ? inputs.typesQuantities.length : 1,
          contagemTiposBilhete: 1,
          multiTicket: multiTicket,
          lingua: 5, 
          gateway_payment: '-', 
          locationPayment: 'Backoffice', 
          paymenttype: 6, 
          user: userId,        
          wreservaId: '',        
          faturacao: inputs.sendInvoice, 
          preferredLanguage: 'en',        
        }, {
          headers: {
            Authorization: `Bearer ${key}`,
          },
        })
        .then(response => {
          console.log(response.data)
          setSuccess(true);
          setError(false);
          setLoading(false);
          reservations.unshift(response.data.reserva);
          console.log(reservations)
          setReservations(reservations);
          //sendTickets(response.data)
          setTimeout(() => {
            toogleModal();
          }, 1500);
          setInputs({});  
        })     
        .catch(error => {
          setSuccess(false);
          setError(true);
          setErrorMessage(error.response.data.message)
          setLoading(false);
          setTimeout(() => {
            toogleModal();
          }, 5000);
          setInputs({});
        });

    } else if (typeContent === 'addSeason') {
      console.log(seasons)
      console.log(inputs)

      try {
        for (let i = 0; i <= seasons.length - 1; i++) {
          if ((moment(inputs.DataInicio).isSameOrBefore(moment(seasons[i].DataFim)) 
            && moment(inputs.DataFim).isSameOrAfter(moment(seasons[i].DataInicio)))
            ||moment(inputs.DataInicio).isAfter(moment(inputs.DataFim))) {

            throw error;
          }
        }

        axios
          .post(BACKEND_DOMAIN + "/epocas", {
            Text: inputs.Text,
            DataInicio: inputs.DataInicio,
            DataFim: inputs.DataFim,
            variedadeBilhetes: inputs.ticketVariety,
            PrecoUnitario: inputs.PrecoUnitario,
            PrecoDesconto: inputs.DiscountPrice, 
            PercentagemDesconto: inputs.DiscountPercentage, 
            QuantidadeDesconto: inputs.DiscountQuantity, 
            lingua: inputs.language,
            servico: serviceId
          }, {
            headers: {
              Authorization: `Bearer ${key}`,
            },
          })
          .then(response => {
            setSuccess(true);
            setError(false);
            setLoading(false);
            seasons.push(response.data);
            setSeasons(seasons);
            setTimeout(() => {
              toogleModal();
            }, 1500);
            setInputs({});
          })
          .catch(error => {
            setSuccess(false);
            setError(true);
            setLoading(false);
            setTimeout(() => {
              toogleModal();
            }, 1500);
            setInputs({});
          });
      } catch (error) {
          setSuccess(false);
          setError(true);
          setLoading(false);
          setTimeout(() => {
            toogleModal();
          }, 1500);
          setInputs({}); 
      }
    } else if (typeContent === 'addTicketType') {
      console.log(inputs)

      if (inputs.season !== '') {
        axios
          .post(BACKEND_DOMAIN + "/tipo-bilhetes", {
            tipoBilhetePT: inputs.ticketTypePT,
            tipoBilheteEN: inputs.ticketTypeEN,
            tipoBilheteFR: inputs.ticketTypeFR,
            precoBilhete: inputs.ticketPrice,
            tipoBilheteAtivo: (inputs.activeTicketType == '' ? false : inputs.activeTicketType),
            epoca: inputs.season,
            servico: serviceId,
          }, {
            headers: {
              Authorization: `Bearer ${key}`,
            },
          }
          )
          .then(response => {
            console.log(response)
            setSuccess(true);
            setError(false);
            setLoading(false);
            ticketTypes.push(response.data);
            setTicketTypes(ticketTypes);
            setTimeout(() => {
              toogleModal();
            }, 1500);
            setInputs({});
          })
        .catch(error => {
          setSuccess(false);
          setError(true);
          setLoading(false);
          setTimeout(() => {
            toogleModal();
          }, 1500);
          setInputs({});
        });
      } else {
        setSuccess(false);
        setError(true);
        setLoading(false);
        setTimeout(() => {
          toogleModal();
        }, 1500);
        setInputs({});
      };

    } else if (typeContent === 'addLanguage') {
      axios
        .post(BACKEND_DOMAIN + "/linguas", {
          nomeLingua_PT: inputs.nomeLingua_PT,
          nomeLingua_EN: inputs.nomeLingua_EN,
          Codigo: inputs.Codigo,
          Lingua_Disponivel: inputs.Lingua_Disponivel,
        }, {
          headers: {
            Authorization: `Bearer ${key}`,
          },
        })
        .then(response => {
          setSuccess(true);
          setError(false);
          setLoading(false);
          languages.push(response.data);
          setLanguages(languages);
          setTimeout(() => {
            toogleModal();
          }, 1500);
          setInputs({});
        })
        .catch(error => {
          setSuccess(false);
          setError(true);
          setLoading(false);
          setTimeout(() => {
            toogleModal();
          }, 1500);
          setInputs({});
        });
    } else if (typeContent === 'addUser') {

    // Creates user:
      await axios
        .post(BACKEND_DOMAIN + "/users", {
          bilheteira: idBilheteira,
          username: inputs.userName,
          password:inputs.password,
          email: inputs.email,
          user_profile: inputs.userProfile,
          servicos: inputs.services,      
          confirmed: inputs.confirmed,
          active: inputs.active,
          //team: inputs.team,         
        }, {
          headers: {
            Authorization: `Bearer ${key}`,
          },
        })
        .then(async response => {
          console.log('ADD TEAM:')
          console.log(response.data)
          console.log(inputs.userProfile)

          setSuccess(true)
          setError(false)
          setLoading(false)

          let userData

          let newTeam

        // Creates team
          if (inputs.userProfile.permissionsLevel == 'Manager' 
            || inputs.userProfile.permissionsLevel == 'Administrator') {
              await axios
                .post(BACKEND_DOMAIN + "/teams/", {
                  manager: inputs.userName,
                  bilheteira: idBilheteira,
                  servicos: inputs.services,                   
                  users: selectedUsers.concat(response.data),
                }, {
                  headers: {
                    Authorization: `Bearer ${key}`,
                  },
                })
                .then(response => {
                  console.log('TEAM CREATED:')
                  console.log(response.data)
                  console.log(teams)

                  setSuccess(true)
                  setError(false)
                  setLoading(false)

                  let newTeams = teams

                  newTeam = response.data

                  newTeams.unshift(response.data)

                  console.log(newTeams)

                  setTeams(newTeams)

                  addsTeamUsers(response.data)
                })
                .catch(error => {
                  console.error(error)

                  setSuccess(false)
                  setError(true)
                  setErrorMessage('Error creating team.')
                  setLoading(false)

                  setTimeout(() => {
                    toogleModal()
                  }, 2500)                  
                })  
          }

          {console.log(userProfile)}
          {console.log('TRACK')}
          {console.log(userObject)}
          {console.log(response.data)}
          {console.log(inputs)}
          {console.log(content)}

          let teamUsers = []

          if (userProfile == 'Manager') { 
            teamUsers = await getTeamUsers(userObject.username)
          } else if (response.data.user_profile.permissionsLevel != 'Manager'
            && response.data.user_profile.permissionsLevel != 'Administrator') {
            teamUsers = inputs.team.users  
          } else {
                    
          }

          teamUsers = teamUsers.concat(response.data)

          {console.log(teamUsers)}


        // Adds new user to selected team
          if (userProfile == 'Manager' 
            || (userProfile == 'Administrator' 
              && (inputs.userProfile.permissionsLevel != 'Manager' && inputs.userProfile.permissionsLevel != 'Administrator'))) {
            console.log('TRACK')
            console.log(userObject.team.id)          

            let teamId

            userProfile == 'Manager' ? teamId = userObject.team.id : teamId = inputs.team.id

            await axios
              .put(BACKEND_DOMAIN + "/teams/"+teamId, {                 
                users: teamUsers,
              }, {
                headers: {
                  Authorization: `Bearer ${key}`,
                },
              })
              .then(response => {
                console.log(response.data)

                setSuccess(true)
                setError(false)
                setLoading(false)

                let newTeams = teams

                newTeams.unshift(response.data)

                setTeams(newTeams)
              })
              .catch(error => {
                console.error(error)
                
                setSuccess(false)
                setError(true)
                setErrorMessage('Error adding manager to team.')
                setLoading(false)

                setTimeout(() => {
                  toogleModal()
                }, 2500)                
              })               
          }          

          let newUsers = users

          console.log(newUsers)

          newUsers.unshift(response.data)

          setUsers(newUsers)
          setSuccess(true)
          setError(false)
          setLoading(false)

          setUsers(users.map(x => {
            if (x.id !== content.id) return x
            return {
              ...x,
              username: inputs.userName,
              password: inputs.password,
              email: inputs.email,
              user_profile: inputs.userProfile,
              servicos: selectedServices,         
              confirmed: inputs.confirmed,
              active: inputs.active,
              team: newTeam ? newTeam : inputs.team
            }
          }))

          setTimeout(() => {
            toogleModal()
          }, 2500)

          setInputs({})
        })
        .catch(error => {
          setSuccess(false)
          setError(true)
          setErrorMessage('Email or username already in use. Please choose a different one.')         
          setLoading(false)

          setTimeout(() => {
            toogleModal()
          }, 2500)

          setInputs({})
        })        
    } else if (typeContent === 'bulkDay') {
      let dataBulk = [
        {
          diaSemana: 'segunda',
          horaInicio: inputs.startHour + ':00.000',
          horaFim: inputs.endHour + ':00.000',
          numBilhetes: inputs.tickets,
          ativo: true,
          epoca: seasonId
        },
        {
          diaSemana: 'terca',
          horaInicio: inputs.startHour + ':00.000',
          horaFim: inputs.endHour + ':00.000',
          numBilhetes: inputs.tickets,
          ativo: true,
          epoca: seasonId
        },
        {
          diaSemana: 'quarta',
          horaInicio: inputs.startHour + ':00.000',
          horaFim: inputs.endHour + ':00.000',
          numBilhetes: inputs.tickets,
          ativo: true,
          epoca: seasonId
        },
        {
          diaSemana: 'quinta',
          horaInicio: inputs.startHour + ':00.000',
          horaFim: inputs.endHour + ':00.000',
          numBilhetes: inputs.tickets,
          ativo: true,
          epoca: seasonId
        },
        {
          diaSemana: 'sexta',
          horaInicio: inputs.startHour + ':00.000',
          horaFim: inputs.endHour + ':00.000',
          numBilhetes: inputs.tickets,
          ativo: inputs.active,
          epoca: seasonId
        }
      ];
      axios
        .post(BACKEND_DOMAIN + "/dias/bulk", dataBulk, {
          headers: {
            Authorization: `Bearer ${key}`,
          },
        }
        )
        .then(response => {
          setSuccess(true);
          setError(false);
          setLoading(false);
          setDays(dataBulk);
          setTimeout(() => {
            toogleModal();
          }, 1500);
          setInputs({});
        })
        .catch(error => {
          setSuccess(false);
          setError(true);
          setLoading(false);
          setTimeout(() => {
            toogleModal();
          }, 1500);
          setInputs({});
        });
    } else if (typeContent === 'bulkBlock') {
      console.log(inputs)

      axios
        .post(BACKEND_DOMAIN + "/bloqueios/bulk", {
          servico: serviceId,
          dataInicio: inputs.dataInicio,
          dataFim: inputs.dataFim,
          visivel: inputs.visivel,
        }, {
          headers: {
            Authorization: `Bearer ${key}`,
          },
        })
        .then(response => {
          console.log(response)
          setSuccess(true);
          setError(false);
          setLoading(false);
          if (response.data.length > 0) {
            for (let i = 0; i < response.data.length; i++) {
              blocks.push(response.data[i]);
            }
            let tempArr = blocks;
            tempArr.sort(function (a, b) {
              if (a.data > b.data) {
                return -1;
              }
              if (a.data < b.data) {
                return 1;
              }
              return 0;
            });
            setBlocks(tempArr);
          }
          setTimeout(() => {
            toogleModal();
          }, 1500);
          setInputs({});
        })
        .catch(error => {
          setSuccess(false);
          setError(true);
          setLoading(false);
          setTimeout(() => {
            toogleModal();
          }, 1500);
          setInputs({});
        });
    } else if (typeContent === 'rangeEvents') {
      console.log(inputs.time)
      console.log(reservationSeasonId)

      axios
        .post(BACKEND_DOMAIN + "/slots/create-event-range", {
          epoca: reservationSeasonId,
          servico: serviceId,          
          dataInicio: inputs.dataInicio,
          dataFim: inputs.dataFim,
          hora: inputs.time ? inputs.time.includes('.000') ? inputs.time : inputs.time.length === 8 ? inputs.time + '.000' :  inputs.time + ':00.000' : '',
        }, {
          headers: {
            Authorization: `Bearer ${key}`,
          },
        })
        .then(response => {
          console.log(response.data)

          setSuccess(true);
          setError(false);
          setLoading(false);

          if (response.data.length > 0) {
            for (let i = 0; i < response.data.length; i++) {
              events.push(response.data[i]);
            }

            let tempArr = events;

            tempArr.sort(function (a, b) {
              if (a.data > b.data) {
                return -1;
              }
              if (a.data < b.data) {
                return 1;
              }
              return 0;
            });

            setEvents(tempArr);
          }
          setTimeout(() => {
            toogleModal();
          }, 1500);
          setInputs({});
        })
        .catch(error => {
          setSuccess(false);
          setError(true);
          setLoading(false);
          setTimeout(() => {
            toogleModal();
          }, 1500);
          setInputs({});
        });
    }
  }

  const sendTickets = async(data) => {
    console.log('DATA:')    
    console.log(data)

    await axios
      .post(BACKEND_DOMAIN + "/reservas/createtickets", {
        reservaData: data.reserva
      }, {
        headers: {
          Authorization: `Bearer ${key}`,
        },
      }) 
    .then(async (response) => { 
      await axios
        .post(BACKEND_DOMAIN + "/reservas/findrestoemail", {
          servico: response.data.servico.id,
        }, {
        headers: {
          Authorization: `Bearer ${key}`,
        },
      }) 
    })
  }       

/**
 * @function editRow - Adds existing rows of each database collection and updates respective application state
 * 
 * @param - typeContent {string} - Defines collection to change and action to take within database and state
 * 
 * TO IMPROVE:
 * @todo ? Pass this function to separate file? As a component?
 */
  const editRow = async () => {
    setLoading(true)
    setSubmit(true)

    if (typeContent === 'editService') {
      axios
        .put(BACKEND_DOMAIN + "/servicos/" + content.id, {
          nome: inputs.serviceName, 
          nomeEN: inputs.serviceNameEN,
          nomeFR: inputs.serviceNameFR,  
          descricao: inputs.description, 
          descricaoEN: inputs.descriptionEN, 
          descricaoFR: inputs.descriptionFR, 
          checkinTextPT: inputs.checkinTextPT,     
          checkinTextEN: inputs.checkinTextEN, 
          checkinTextFR: inputs.checkinTextFR,   
          checkoutTextPT: inputs.checkoutTextPT,     
          checkoutTextEN: inputs.checkoutTextEN, 
          checkoutTextFR: inputs.checkoutTextFR,                                                                         
          url: inputs.urlPath, 
          imagemEvento: inputs.serviceImage, 
          duracao: inputs.duration, 
          intervalo: inputs.break,
          horaLimite: inputs.limitHour ? inputs.limitHour.includes('.000') ? inputs.limitHour : inputs.limitHour.length === 8 ? inputs.limitHour + '.000' :  inputs.limitHour + ':00.000' : '', 
          timeAdvance: inputs.timeAdvance,
          timeLimitOverride: inputs.timeLimitOverride,
          timeAdvanceType: inputs.timeAdvanceType,          
          ativo: inputs.activeService,  
          backupEmailServico: inputs.backupEmailService,
          pagamentos: inputs.payments, 
          faturacao: inputs.invoicing,
          urlDomain: inputs.urlDomain,
          urlInfo: inputs.urlInfo,         
        }, {
          headers: {
            Authorization: `Bearer ${key}`,
          },
        })
        .then(response => {
          console.log(response.data)

          setSuccess(true)
          setError(false)
          setLoading(false)

          console.log(services)

          setServices(services.map(x => {
            if (x.id !== content.id) return x
            return {
              ...x,
              nome: inputs.serviceName,
              nomeEN: inputs.serviceNameEN,
              nomeFR: inputs.serviceNameFR,                                 
              descricao: inputs.description, 
              descricaoEN: inputs.descriptionEN,
              descricaoFR: inputs.descriptionFR,                
              url: inputs.urlPath, 
              imagemEvento: inputs.serviceImage, 
              duracao: inputs.duration, 
              intervalo: inputs.break,
              horaLimite: inputs.limitHour ? inputs.limitHour.includes('.000') ? inputs.limitHour : inputs.limitHour.length === 8 ? inputs.limitHour + '.000' :  inputs.limitHour + ':00.000' : '',  
              timeAdvance: inputs.timeAdvance,
              timeLimitOverride: inputs.timeLimitOverride,
              timeAdvanceType: inputs.timeAdvanceType,
              ativo: inputs.activeService,
              backupEmailServico: inputs.backupEmailService
            }
          }))

          console.log(services)

          setTimeout(() => {
            toogleModal()
          }, 1500)

          setInputs({})
        })
        .catch(error => {
          setSuccess(false);
          setError(true);
          setLoading(false);
          setTimeout(() => {
            toogleModal();
          }, 1500);
          setInputs({});
        });
    } else if (typeContent === 'editEvent') {
      axios
        .put(BACKEND_DOMAIN + "/slots/" + content.id, {
          data: inputs.date,
          hora: inputs.time ? inputs.time.includes('.000') ? inputs.time : inputs.time.length === 8 ? inputs.time + '.000' :  inputs.time + ':00.000' : '',
        }, {
          headers: {
            Authorization: `Bearer ${key}`,
          },
        }
        )
        .then(response => {
          console.log(response.data)

          setSuccess(true);
          setError(false);
          setLoading(false);
          setEvents(events.map(x => {
            if (x.id !== content.id) return x
            if (response.data.hora == null) {
              return {
                ...x,
                data: inputs.date,
                hora: null,
              }  
            } else {
              return {
                ...x,
                data: inputs.date,
                hora: inputs.time ? inputs.time.includes('.000') ? inputs.time : inputs.time.length === 8 ? inputs.time + '.000' :  inputs.time + ':00.000' : '',
              }
            }
          }));

          setTimeout(() => {
            toogleModal();
          }, 1500);
          setInputs({});
        })
        .catch(error => {
          setSuccess(false);
          setError(true);
          setLoading(false);
          setTimeout(() => {
            toogleModal();
          }, 1500);
          setInputs({});
        });
        } else if (typeContent === 'editBlock') {
      axios
        .put(BACKEND_DOMAIN + "/bloqueios/" + content.id, {
          data: inputs.date,
          slot: inputs.slot == '' ? undefined : inputs.slot,
          servico: serviceId,
          visivel:inputs.visivel,
        }, {
          headers: {
            Authorization: `Bearer ${key}`,
          },
        }
        )
        .then(response => {
          console.log(response)
          setSuccess(true);
          setError(false);
          setLoading(false);
          setBlocks(blocks.map(x => {
            if (x.id !== content.id) return x
            if (response.data.slot == null) {
              return {
                ...x,
                data: inputs.date,
                slot: null,
                visivel: inputs.visivel,
              }  
            } else {
              return {
                ...x,
                data: inputs.date,
                slot: inputs.slot == '' ? undefined : inputs.slot,
                visivel: inputs.visivel,
              }
            }
          }));

          setTimeout(() => {
            toogleModal();
          }, 1500);
          setInputs({});
        })
        .catch(error => {
          setSuccess(false);
          setError(true);
          setLoading(false);
          setTimeout(() => {
            toogleModal();
          }, 1500);
          setInputs({});
        });
    } else if (typeContent === 'editSlotLanguage') {
      console.log(inputs)

      try {
        for (let i = 0; i <= slotsLanguages.length - 1; i++) {

          if ((slotsLanguages[i].Data == inputs.date && slotsLanguages[i].slot == inputs.slot + '.000')
            && !(moment(editedSlotLanguage.date).isSame(moment(slotsLanguages[i].Data))) 
            && !(moment(editedSlotLanguage.slot).isSame(moment(slotsLanguages[i].slot))))
           
            throw error;
          }

        axios
          .put(BACKEND_DOMAIN + "/lingua-slots/" + content.id, {
            Data: inputs.date,
            slot: inputs.slot == '' ? undefined : inputs.slot,
            lingua: inputs.language,
            servico: serviceId
          }, {
            headers: {
              Authorization: `Bearer ${key}`,
            },
          }
          )
          .then(response => {
            setSuccess(true);
            setError(false);
            setLoading(false);
            setSlotsLanguages(slotsLanguages.map(x => {
              if (x.id !== content.id) return x
              return {
                ...x,
                Data: inputs.date,
                slot: inputs.slot == '' ? undefined : inputs.slot,
                lingua: inputs.language,
              }
            }));
            setTimeout(() => {
              toogleModal();
            }, 1500);
            setInputs({});
          })      
          .catch(error => {
            setSuccess(false);
            setError(true);
            setLoading(false);
            setTimeout(() => {
              toogleModal();
            }, 1500);
            setInputs({});
          });              
      } catch (error) {
          setSuccess(false);
          setError(true);
          setLoading(false);
          setTimeout(() => {
            toogleModal();
          }, 1500);
          setInputs({});
      }       
      
    } else if (typeContent === 'editDiscount') {
      axios
        .put(BACKEND_DOMAIN + "/descontos/" + content.id, {
          Sobre: inputs.about,
          comparador: inputs.comparator,
          valor: inputs.value,
          desconto: inputs.discount,
          epoca: seasonId
        }, {
          headers: {
            Authorization: `Bearer ${key}`,
          },
        }
        )
        .then(response => {
          setSuccess(true);
          setError(false);
          setLoading(false);
          setDiscounts(discounts.map(x => {
            if (x.id !== content.id) return x
            return {
              ...x,
              Sobre: inputs.about,
              comparador: inputs.comparator,
              valor: inputs.value,
              desconto: inputs.discount
            }
          }));
          setTimeout(() => {
            toogleModal();
          }, 1500);
          setInputs({});
        })
        .catch(error => {
          setSuccess(false);
          setError(true);
          setLoading(false);
          setTimeout(() => {
            toogleModal();
          }, 1500);
          setInputs({});
        });
    } else if (typeContent === 'editDay') {
      axios
        .put(BACKEND_DOMAIN + "/dias/" + content.id, {
          diaSemana: inputs.dayweek,
          horaInicio: inputs.startHour,
          horaFim: inputs.endHour,
          numBilhetes: inputs.tickets,
          ativo: inputs.active,
          epoca: seasonId
        }, {
          headers: {
            Authorization: `Bearer ${key}`,
          },
        }
        )
        .then(response => {
          setSuccess(true)
          setError(false)
          setLoading(false)
          setDays(days.map(x => {
            if (x.id !== content.id) return x
            return {
              ...x,
              diaSemana: inputs.dayweek,
              horaInicio: inputs.startHour,
              horaFim: inputs.endHour,
              numBilhetes: inputs.tickets,
              ativo:inputs.active,
            }
          }))

          
          setTimeout(() => {
            toogleModal();
          }, 1500);
          setInputs({});
        })
        .catch(error => {
          setSuccess(false);
          setError(true);
          setLoading(false);
          setTimeout(() => {
            toogleModal();
          }, 1500);
          setInputs({});
        });
    } else if (typeContent === 'editReservation') {
      console.log('EDIT RESERVATION')
      console.log(inputs)
      axios
        .put(BACKEND_DOMAIN + "/reservas/" + content.id, {
          servico: inputs.servico,
          data: inputs.date + 'T' + (inputs.slot.length === 11 ? '0' + inputs.slot+'Z': inputs.slot+'Z'),          
          nome: inputs.name,
          email: inputs.email,
          pais: inputs.country,
          contacto:inputs.contact,
          nif: inputs.vat,
          obs: inputs.obs,
          quantidade: inputs.quantity,
          quantidadesTipos: inputs.typesQuantities,
          tiposreservas: inputs.typesReservation,
          numTiposBilhete: content.numTiposBilhete,
          multiTicket: inputs.multiTicket,  
        }, {
          headers: {
            Authorization: `Bearer ${key}`,
          },
        }
        )
        .then(response => {
          setSuccess(true)
          setError(false)
          setLoading(false)

          setReservations(reservations.map(x => {
            if (x.id !== content.id) return x
            return {
              ...x,
              nome: response.data.nome,
              email: response.data.email,
              pais: response.data.pais,
              nif: response.data.nif,
              obs: response.data.obs,
              tipo_bilhete: response.data.tipo_bilhete,
              quantidade: response.data.quantidade,
              data: response.data.data,
              tiposreservas: response.data.tiposreservas,               
            }
          }))

          setTimeout(() => {
            toogleModal();
          }, 1500)

          setInputs({})
        })
        .catch(error => {
          console.error(error)
          
          setSuccess(false)
          setError(true)
          setLoading(false)
          setTimeout(() => {
            toogleModal()
          }, 1500);
          setInputs({});
        });
    } else if (typeContent === 'editCoupon') {
      axios
        .put(BACKEND_DOMAIN + "/codigos/" + content.id, {
          nome: inputs.name,
          codigo: inputs.code,
          desconto: inputs.discount,
          dataInicio: inputs.startDate,
          dataFim: inputs.endDate,
          utilizacoes: inputs.utilizations,
          servico: serviceId
        }, {
          headers: {
            Authorization: `Bearer ${key}`,
          },
        }
        )
        .then(response => {
          setSuccess(true);
          setError(false);
          setLoading(false);
          setCoupons(coupons.map(x => {
            if (x.id !== content.id) return x
            return {
              ...x,
              nome: inputs.name,
              codigo: inputs.code,
              desconto: inputs.discount,
              dataInicio: inputs.startDate,
              dataFim: inputs.endDate,
              utilizacoes: inputs.utilizations,
            }
          }));
          setTimeout(() => {
            toogleModal();
          }, 1500);
          setInputs({});
        })
        .catch(error => {
          setSuccess(false);
          setError(true);
          setLoading(false);
          setTimeout(() => {
            toogleModal();
          }, 1500);
          setInputs({});
        });
    } else if (typeContent === 'editSeason') {
      console.log(inputs);

      try {
        for (let i = 0; i <= seasons.length - 1; i++) {
          if ((((moment(inputs.DataInicio).isSameOrBefore(moment(seasons[i].DataFim)) 
            && moment(inputs.DataFim).isSameOrAfter(moment(seasons[i].DataInicio))))
            && !(moment(editedSeason.DataInicio).isSame(moment(seasons[i].DataInicio))))
            || moment(inputs.DataInicio).isAfter(moment(inputs.DataFim))) {

            throw error;
          }
        }

        axios
          .get(BACKEND_DOMAIN + "/epocas/" + content.id, {
            headers: {
              Authorization: `Bearer ${key}`,
            },
          })
          .then(response => {
            console.log(response.data)
          });

        axios
          .put(BACKEND_DOMAIN + "/epocas/" + content.id, {
            Text: inputs.Text,
            DataInicio: moment(inputs.DataInicio).format('YYYY-MM-DD'),
            DataFim: moment(inputs.DataFim).format('YYYY-MM-DD'),
            variedadeBilhetes: inputs.ticketVariety,
            PrecoUnitario: inputs.PrecoUnitario,
            PrecoDesconto: inputs.DiscountPrice, 
            PercentagemDesconto: inputs.DiscountPercentage, 
            QuantidadeDesconto: inputs.DiscountQuantity,             
            lingua: inputs.language == null || Object.keys(inputs.language).length !== 0 ? inputs.language : null,
            servico: serviceId
          }, {
            headers: {
              Authorization: `Bearer ${key}`,
            },
          }
          )
          .then(response => {
            console.log(response)
            
            setSuccess(true)
            setError(false)
            setLoading(false)
            setSeasons(seasons.map(x => {
              if (x.id !== content.id) return x
              return {
                ...x,
                Text: inputs.Text,
                DataInicio: inputs.DataInicio,
                DataFim: inputs.DataFim,
                variedadeBilhetes: inputs.ticketVariety,                
                PrecoUnitario: inputs.PrecoUnitario,
                PrecoDesconto: inputs.DiscountPrice, 
                PercentagemDesconto: inputs.DiscountPercentage, 
                QuantidadeDesconto: inputs.DiscountQuantity,                     
                lingua: inputs.language,
              }
            }));
            setTimeout(() => {
              toogleModal();
            }, 1500);
            setInputs({});
          })
          .catch(error => {
            setSuccess(false);
            setError(true);
            setLoading(false);
            setTimeout(() => {
              toogleModal();
            }, 1500);
            setInputs({});
          });
       } catch (error) {
          setSuccess(false);
          setError(true);
          setLoading(false);
          setTimeout(() => {
            toogleModal();
          }, 1500);
          setInputs({});
      }       
    } else if (typeContent === 'editLanguage') {
      console.log(inputs);

      axios
        .get(BACKEND_DOMAIN + "/linguas/" + content.id, {
          headers: {
            Authorization: `Bearer ${key}`,
          },
        })
        .then(response => {
          console.log(response)
        });

      axios
        .put(BACKEND_DOMAIN + "/linguas/" + content.id, {
          nomeLingua_PT: inputs.nomeLingua_PT,
          nomeLingua_EN: inputs.nomeLingua_EN,
          Codigo: inputs.Codigo,
          Lingua_Disponivel: inputs.Lingua_Disponivel,
        }, {
          headers: {
            Authorization: `Bearer ${key}`,
          },
        }
        )
        .then(response => {
          console.log(response);
          setSuccess(true);
          setError(false);
          setLoading(false);
          setLanguages(languages.map(x => {
            if (x.id !== content.id) return x
            return {
              ...x,
              nomeLingua_PT: inputs.nomeLingua_PT,
              nomeLingua_EN: inputs.nomeLingua_EN,
              Codigo: inputs.Codigo,
              Lingua_Disponivel: inputs.Lingua_Disponivel,
            }
          }));
          setTimeout(() => {
            toogleModal();
          }, 1500);
          setInputs({});
        })
        .catch(error => {
          setSuccess(false);
          setError(true);
          setLoading(false);
          setTimeout(() => {
            toogleModal();
          }, 1500);
          setInputs({});
        });
    } else if (typeContent === 'editUser') {
      console.log(inputs)
      console.log(content)

    // Admin or manager mode - Deletes team when changed from Admin or Manager
      if ((content.user_profile.permissionsLevel == 'Manager' || content.user_profile.permissionsLevel == 'Administrator')
        && inputs.userProfile.permissionsLevel != 'Manager' && inputs.userProfile.permissionsLevel != 'Administrator') {
          if (content.team) {
            axios
              .delete(BACKEND_DOMAIN + "/teams/"+content.team.id, {
                headers: {
                  Authorization: `Bearer ${key}`,
                },
              })
              .then(response => {
                console.log(response.data)

                setSuccess(true)
                setError(false)
                setLoading(false)
                setTeams(teams.filter(x => x.id !== content.team.id))

                setTimeout(() => {
                  toogleModal()
                }, 1500)
              })
              .catch(error => {
                setSuccess(false)
                setError(true)
                setLoading(false)
                setTimeout(() => {
                  toogleModal()
                }, 1500)
              })
          }
      }        

    // Admin or manager mode - Changes team name when manager's username changes
      if (inputs.userName != content.username && (inputs.userProfile.permissionsLevel == 'Manager'
        || inputs.userProfile.permissionsLevel == 'Administrator')) {
          axios
            .put(BACKEND_DOMAIN + "/teams/" + content.team.id,{
              manager: inputs.userName
            }, {
              headers: {
                Authorization: `Bearer ${key}`,
              },
            })
            .then(response => {
              console.log(response.data)

              setSuccess(true)
              setError(false)
              setLoading(false)
              setTeams(teams.map(x => {
                if (x.id !== content.team.id) return x
                return {
                  ...x,
                  manager: inputs.userName
                }
              }))

              setTimeout(() => {
                toogleModal()
              }, 1500)
            })
            .catch(error => {
              setSuccess(false)
              setError(true)
              setLoading(false)
              setTimeout(() => {
                toogleModal()
              }, 1500)
            })
      }     

      let newTeam           

    // Edits user
      await axios
        .put(BACKEND_DOMAIN + "/users/" + content.id, {
          username: inputs.userName,
          password: inputs.password,
          email: inputs.email,
          user_profile: inputs.userProfile,
          servicos: selectedServices,         
          confirmed: inputs.confirmed,
          active: inputs.active,
          team: inputs.team && Object.keys(inputs.team).length !== 0 ? inputs.team 
            : content.team && Object.keys(content.team).length !== 0 ? content.team
            : null          
        }, {
          headers: {
            Authorization: `Bearer ${key}`,
          },
        })
        .then(async response => {
          console.log(response.data)
          console.log(selectedUsers)
          console.log(inputs)
          console.log(content)

        // Admin or manager mode - Creates team when userprofile changes to Admin or Manager
          if ((content.user_profile.permissionsLevel != 'Manager' && content.user_profile.permissionsLevel != 'Administrator')
            && (inputs.userProfile.permissionsLevel == 'Manager' || inputs.userProfile.permissionsLevel == 'Administrator')) {
              await axios
                .post(BACKEND_DOMAIN + "/teams/", {
                  manager: inputs.userName,
                  bilheteira: idBilheteira,
                  servicos: inputs.services,                   
                  users: selectedUsers ? selectedUsers.concat(response.data) : response.data,
                }, {
                  headers: {
                    Authorization: `Bearer ${key}`,
                  },
                })
                .then(response => {
                  console.log('CREATING TEAM:')
                  console.log(response.data)
                  console.log(teams)

                  setSuccess(true)
                  setError(false)
                  setLoading(false)

                  let newTeams = teams

                  newTeams.unshift(response.data)

                  console.log(newTeams)

                  setTeams(newTeams)

                  newTeam = response.data
                })
                .catch(error => {
                  console.error(error)

                  setSuccess(false)
                  setError(true)
                  setErrorMessage('Error creating team.')
                  setLoading(false)

                  setTimeout(() => {
                    toogleModal()
                  }, 2500)                  
                })  
          }   
                     
          setSuccess(true)
          setError(false)
          setLoading(false)

          {console.log('TRACK')}
          {console.log(inputs)}
          {console.log(newTeam)}

          setUsers(users.map(x => {
            if (x.id !== content.id) return x
            return {
              ...x,
              username: inputs.userName,
              password: inputs.password,
              email: inputs.email,
              user_profile: inputs.userProfile,
              servicos: selectedServices,         
              confirmed: inputs.confirmed,
              active: inputs.active,
              team: newTeam ? newTeam : inputs.team
            }
          }))

          setTimeout(() => {
            toogleModal()
          }, 1500)
        })
        .catch(error => {
          setSuccess(false)
          setError(true)
          setLoading(false)
          setTimeout(() => {
            toogleModal()
          }, 1500)
        })  

    // Admin mode - Adds users list to manager team when user profile is manager
      if (inputs.userProfile.permissionsLevel == 'Manager') addsTeamUsers(newTeam)                 

      setInputs({})        
    } else if (typeContent === 'editTicketOffice') {
      axios
        .put(BACKEND_DOMAIN + "/bilheteiras/" + content.id, {
          nome: inputs.title,
          descricao: inputs.description,
          url: inputs.url,
          email: inputs.email,
          Pagamentos: inputs.Pagamentos,
          stripe_api_key: inputs.stripe_api_key
        }, {
          headers: {
            Authorization: `Bearer ${key}`,
          },
        })
        .then(response => {
          setSuccess(true);
          setError(false);
          setLoading(false);
          setTicketOffice({
            id: content.id,
            name: inputs.title,
            description: inputs.description,
            namePT: inputs.titlePT,
            descriptionPT: inputs.descriptionPT,
            nameFR: inputs.titleFR,
            descriptionFR: inputs.descriptionFR,
            url: inputs.url,
            email: inputs.email,
            Pagamentos: inputs.Pagamentos,
            stripe_api_key: inputs.stripe_api_key
          });
          setTimeout(() => {
            toogleModal();
          }, 1500);
          setInputs({});
        })
        .catch(error => {
          setSuccess(false);
          setError(true);
          setLoading(false);
          setTimeout(() => {
            toogleModal();
          }, 1500);
          setInputs({});
        });
    } else if (typeContent === 'editTicketType') {
      axios
          .put(BACKEND_DOMAIN + "/tipo-bilhetes/" + content.id, {
            tipoBilhetePT: inputs.ticketTypePT,
            tipoBilheteEN: inputs.ticketTypeEN,
            tipoBilheteFR: inputs.ticketTypeFR,
            precoBilhete: inputs.ticketPrice,
            tipoBilheteAtivo: inputs.activeTicketType,
            epoca: inputs.season,
            servico: serviceId
          }, {
            headers: {
              Authorization: `Bearer ${key}`,
            },
          })
          .then(response => {
            console.log(response);
            setSuccess(true);
            setError(false);
            setLoading(false);
            setTicketTypes(ticketTypes.map(x => {
              if (x.id !== content.id) return x
              return {
                ...x,
                tipoBilheteEN: inputs.ticketTypeEN,
                tipoBilhetePT: inputs.ticketTypePT,
                tipoBilheteFR: inputs.ticketTypeFR,
                precoBilhete: inputs.ticketPrice,
                tipoBilheteAtivo: inputs.activeTicketType,
                epoca: inputs.season,
              }
            }));
            setTimeout(() => {
              toogleModal();
            }, 1500);
            setInputs({});
          })      
          .catch(error => {
            setSuccess(false);
            setError(true);
            setLoading(false);
            setTimeout(() => {
              toogleModal();
            }, 1500);
            setInputs({});
          });              
      }       
  }


/**
 * @function deleteRow - Deletes rows from each database collection and updates each application state
 * 
 * @param - typeContent {string} - Defines collection to change and action to take within database and state
 * 
 * TO IMPROVE:
 * @todo ? Pass this function to separate file? As a component?
 */
  const deleteRow = () => {
    if (typeContent === 'deleteService') {
      console.log(services)

      axios
        .delete(BACKEND_DOMAIN + "/servicos/" + content.id, {
          headers: {
            Authorization: `Bearer ${key}`,
          },
        }
        )
        .then(response => {
          console.log(response)
          console.log(services)

          services.splice(services.findIndex(function (i) {
            return i.id === content.id
          }), 1)

          setServices(services)

          setTimeout(() => {
            toogleModal();
          }, 200);
        })
        .catch(error => {
        });
    } else if (typeContent === 'deleteBlock') {
      axios
        .delete(BACKEND_DOMAIN + "/bloqueios/" + content.id, {
          headers: {
            Authorization: `Bearer ${key}`,
          },
        }
        )
        .then(response => {
          blocks.splice(blocks.findIndex(function (i) {
            return i.id === content.id;
          }), 1)
          setBlocks(blocks);
          setTimeout(() => {
            toogleModal();
          }, 200);
        })
        .catch(error => {
        });
    } else if (typeContent === 'deleteEvent') {
      axios
        .delete(BACKEND_DOMAIN + "/slots/" + content.id, {
          headers: {
            Authorization: `Bearer ${key}`,
          },
        })
        .then(response => {
          events.splice(events.findIndex(function (i) {
            return i.id === content.id;
          }), 1)
          setEvents(events);
          setTimeout(() => {
            toogleModal();
          }, 200);
        })
        .catch(error => {
        });
    } else if (typeContent === 'deleteSlotLanguage') {
      console.log(slotsLanguages);
      axios
        .delete(BACKEND_DOMAIN + "/lingua-slots/" + content.id, {
          headers: {
            Authorization: `Bearer ${key}`,
          },
        }
        )
        .then(response => {
          console.log(response);

          slotsLanguages.splice(slotsLanguages.findIndex(function (i) {
            return i.id === content.id;
          }), 1)
          setSlotsLanguages(slotsLanguages);
          setTimeout(() => {
            toogleModal();
          }, 200);
        })
        .catch(error => {
        });

    } else if (typeContent === 'deleteDiscount') {
      axios
        .delete(BACKEND_DOMAIN + "/descontos/" + content.id, {
          headers: {
            Authorization: `Bearer ${key}`,
          },
        }
        )
        .then(response => {
          discounts.splice(discounts.findIndex(function (i) {
            return i.id === content.id;
          }), 1)
          setDiscounts(discounts);
          setTimeout(() => {
            toogleModal();
          }, 200);

        })
        .catch(error => {
        });
    } else if (typeContent === 'deleteDay') {
      axios
        .delete(BACKEND_DOMAIN + "/dias/" + content.id, {
          headers: {
            Authorization: `Bearer ${key}`,
          },
        }
        )
        .then(response => {
          days.splice(days.findIndex(function (i) {
            return i.id === content.id;
          }), 1)
          setDays(days);
          setTimeout(() => {
            toogleModal();
          }, 200);
        })
        .catch(error => {
        });
    } else if (typeContent === 'deleteReservation') {
      axios
        .delete(BACKEND_DOMAIN + "/reservas/" + content.id, {
          headers: {
            Authorization: `Bearer ${key}`,
          },
        })
        .then(response => {
          reservations.splice(reservations.findIndex(function (i) {
            return i.id === content.id;
          }), 1)
          setReservations(reservations);
          setTimeout(() => {
            toogleModal();
          }, 200);
        })
        .catch(error => {
        });
    } else if (typeContent === 'deleteCoupon') {
      axios
        .delete(BACKEND_DOMAIN + "/codigos/" + content.id, {
          headers: {
            Authorization: `Bearer ${key}`,
          },
        }
        )
        .then(response => {
          coupons.splice(coupons.findIndex(function (i) {
            return i.id === content.id;
          }), 1)
          setCoupons(coupons);
          setTimeout(() => {
            toogleModal();
          }, 200);
        })
        .catch(error => {
        });
    } else if (typeContent === 'deleteSeason') {
      axios
        .delete(BACKEND_DOMAIN + "/epocas/" + content.id, {
          headers: {
            Authorization: `Bearer ${key}`,
          },
        }
        )
        .then(response => {
          seasons.splice(seasons.findIndex(function (i) {
            return i.id === content.id;
          }), 1)
          setSeasons(seasons);
          setTimeout(() => {
            toogleModal();
          }, 200);
        })
        .catch(error => {
        });
    } else if (typeContent === 'deleteLanguage') {
      axios
        .delete(BACKEND_DOMAIN + "/linguas/" + content.id, {
          headers: {
            Authorization: `Bearer ${key}`,
          },
        }
        )
        .then(response => {
          languages.splice(languages.findIndex(function (i) {
            return i.id === content.id;
          }), 1)
          setLanguages(languages);
          setTimeout(() => {
            toogleModal();
          }, 200);
        })
        .catch(error => {
        });
    } else if (typeContent === 'deleteTicketType') {
      axios
        .delete(BACKEND_DOMAIN + "/tipo-bilhetes/" + content.id, {
          headers: {
            Authorization: `Bearer ${key}`,
          },
        }
        )
        .then(response => {
          console.log(response)
          ticketTypes.splice(ticketTypes.findIndex(function (i) {
            return i.id === content.id;
          }), 1)
          setTicketTypes(ticketTypes);
          setTimeout(() => {
            toogleModal();
          }, 200);
        })
        .catch(error => {
          console.log(error)
        });
    } else if (typeContent === 'deleteUser') {
      axios
        .delete(BACKEND_DOMAIN + "/users/" + content.id, {
          headers: {
            Authorization: `Bearer ${key}`,
          },
        })
        .then(response => {
          console.log(response.data)

          let newUsers = users

          newUsers.splice(newUsers.findIndex(function (i) {
            return i.id === content.id;
          }), 1)

          setUsers(newUsers)

          teams.map(item => {
            if (item.manager == response.data.username) {
              axios
                .delete(BACKEND_DOMAIN + "/teams/" + item.id, {
                  headers: {
                    Authorization: `Bearer ${key}`,
                  },
                })
                .then(response => {
                  console.log(response.data)

                  let newTeams = teams
                  
                  newTeams.splice(newTeams.findIndex(function (i) {
                    return i.id === content.id;
                  }), 1)

                  setTeams(newTeams)
                })
                .catch(error => {
                  console.log(error)
                })             
            }
            setTimeout(() => {
              toogleModal()
            }, 200)
          })
        })
        .catch(error => {
          console.log(error)
        })
    }
  }


/**
 * @function checkService - Saves services collection from database into application state
 *   in the form of value/label pairs
 * 
 * CALLED BY:
 * - When modal/pop-up is opened or closed
 */
  const checkService = () => {
    setLoading(true)

    axios
      .get(BACKEND_DOMAIN + "/servicos" + '?bilheteira=' + idBilheteira + '&_limit=-1', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(response => {
        setAllServices(response.data)

        let temp = []

        for (let i = 0; i < response.data.length; i++) {
          temp.push({
            value: response.data[i].id,
            label: response.data[i].nome
          })
        }

        console.log(temp)       

        setServicesOptions(temp)

        setLoading(false)
      })
      .catch(error => {
        console.error(error)

        setLoading(false)
      })
  }

/**
 * @function checkUser - Saves users collection from database into application state
 *   in the form of value/label pairs
 * 
 * CALLED BY:
 * - When modal/pop-up is opened or closed
 */
  const checkUser = () => {
    setLoading(true)

    axios
      .get(BACKEND_DOMAIN + "/users" + '?bilheteira=' + idBilheteira + '&_limit=-1', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(response => {
        setAllUsers(response.data)

        let temp = []

        for (let i = 0; i < response.data.length; i++) {
          temp.push({
            value: response.data[i].id,
            label: response.data[i].username
          })
        }

        console.log(temp)       

        setUsersOptions(temp)

        setLoading(false)
      })
      .catch(error => {
        console.error(error)

        setLoading(false)
      })
  }


/**
 * @function filterLanguages - Returns language value/label pair, based on dropdown field selected
 *   language option.
 * 
 * CALLED BY:
 * - When dropdown element is rendered.
 */
  const filterLanguages = () => {
    if(inputs.language != undefined) 
      return languageOptions.filter(data => (data.label === inputs.language.nomeLingua_EN)) 
        ? languageOptions.filter(data => (data.label === inputs.language.nomeLingua_EN)) 
        : ''
  }


/**
 * @function filterSeason - Returns language value/label pair, based on dropdown field selected
 *   season option.
 * 
 * CALLED BY:
 * - When dropdown element is rendered.
 */
  const filterSeasons = () => {
    if(inputs.season != undefined) 
      return seasonOptions.filter(data => (data.value === inputs.season.Text)) 
        ? seasonOptions.filter(data => (data.value === inputs.season.Text)) 
        : ''
  }


/**
 * @function filterProfiles - Returns profile value/label pair, based on dropdown field selected
 *   profile option.
 * 
 * CALLED BY:
 * - When dropdown element is rendered.
 */
  const filterProfiles = () => {
    if(inputs.userProfile != undefined) 
      return profileOptions.filter(data => (data.value === inputs.userProfile.permissionsLevel)) 
        ? profileOptions.filter(data => (data.value === inputs.userProfile.permissionsLevel)) 
        : ''
  }

/**
 * @function filterTeams - 
 *   profile option.
 * 
 * CALLED BY:
 * - When dropdown element is rendered.
 */
  const filterTeams = () => {
    if(inputs.team != undefined) 
      return teamOptions.filter(data => (data.value === inputs.team.manager)) 
        ? teamOptions.filter(data => (data.value === inputs.team.manager)) 
        : ''
  }

/**
 * @function filterTickets - Returns ticket types value/label pair, based on dropdown field selected
 *   ticket option.
 * 
 * CALLED BY:
 * - When dropdown element is rendered.
 */
  const filterTickets = () => {

    if(inputs.ticketType != undefined) 
      console.log(inputs.ticketType)
      console.log(ticketOptions)

      return ticketOptions.filter(data => (data.value === inputs.ticketType.tipoBilheteEN)) 
        ? ticketOptions.filter(data => (data.value === inputs.ticketType.tipoBilheteEN)) 
        : ''
  }


/**
 * @function saveEditedSeason - Saves edited season to avoid dates overlaping error 
 * 
 * CALLED BY:
 * - When dropdown element is rendered.
 *
 * TO IMPROVE:
 * - 
 */
  const saveEditedSeason = () => {
    setEditedSeason({
      DataInicio: content.DataInicio, 
      DataFim: content.DataFim,
    }) 
  }


/**
 * @function saveEditedSlotLanguage - Saves edited slot language to avoid dates overlaping error 
 * 
 * CALLED BY:
 * - When dropdown element is rendered.
 *
 * TO IMPROVE:
 * - 
 */
  const saveEditedSlotLanguage = () => {
    setEditedSlotLanguage({
      date: content.Data,
      slot: content.slot,
    })
  }


/**
 * @function getUserServices - Gets user permited services
 *
 * CALLED BY:
 * - On 1st render
 *
 * TO IMPROVE:
 * - 
 */
  const getUserServices = async (id) => {
    console.log("GETTING USER SERVICES")
    console.log(id)

    if (id) {
      await axios
        .get(BACKEND_DOMAIN + "/users/"+ id, {
          headers: {
            Authorization: `Bearer ${key}`,
          }
        })
        .then(response => {
          console.log(response.data)

          const checkedServices = response.data.servicos.map((service, index) => (service))

          setSelectedServices(checkedServices)
        })   
        .catch(error => {
          console.error(error)
        })
    }
  } 

/**
 * @function getTeamUsers -
 *
 * CALLED BY:
 * - On 1st render
 *
 * TO IMPROVE:
 * - 
 */
  const getTeamUsers = async (manager) => {
    console.log("GETTING TEAM USERS:")
    console.log(manager)

    let checkedTeamUsers = []

    if (manager) {
      await axios
        .get(BACKEND_DOMAIN + "/teams?manager="+manager,{         
          headers: {
            Authorization: `Bearer ${key}`,
          }
        })
        .then(response => {
          console.log(response.data)

          if (response.data.length != 0) checkedTeamUsers = response.data[0].users

          setSelectedUsers(checkedTeamUsers)          
        })   
        .catch(error => {
          console.error(error)
        })
    }

    return checkedTeamUsers
  }   


/**
 * @function changeServiceCheck - ...
 *
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * - 
 */
  const changeServiceCheck = () => {
    console.log(servicesOptions)
    console.log(serviceIndex)

    let removed = false

    const newSelectedServices = selectedServices

    if (serviceIndex != -1) {
      selectedServices.map((service, index) => {
        console.log(index)

        if (service.nome == servicesOptions[serviceIndex].label) {
          newSelectedServices.splice(index,1)
          removed = true
        }
      })

      if (removed == false) {allServices.map(service => {
        if (service.nome == servicesOptions[serviceIndex].label) {
          newSelectedServices.push(service)
        }
      })}

      console.log(newSelectedServices)

      inputs.services = newSelectedServices

      setServiceIndex(-1)

      setSelectedServices(newSelectedServices)
    }
  }


/**
 * @function changeTeamUsersCheck - ...
 *
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * - 
 */
  const changeTeamUsersCheck = () => {
    console.log(usersOptions)
    console.log(userIndex)
    console.log(selectedUsers)

    let removed = false

    const newSelectedUsers = selectedUsers

    if (userIndex != -1 && userIndex != null) {
      selectedUsers.map((usr, index) => {
        console.log(index)

        if (usr.username == usersOptions[userIndex].label) {
          newSelectedUsers.splice(index,1)
          removed = true
        }
      })

      if (removed == false) {allUsers.map(usr => {        
        if (usr.username == usersOptions[userIndex].label) {
          console.log(usr)
          
          newSelectedUsers.push(usr)
        }
      })}

      console.log(newSelectedUsers)

      setUserIndex(-1)
      setSelectedUsers(newSelectedUsers)
    }
  }  


  return (
    <DivModal isOpen={isOpen}>
      {submit === false &&
        <>
          {(typeContent === 'addBlock' || typeContent === 'editBlock') &&
            <Content>
              <Container>
                <Text14
                  dangerouslySetInnerHTML={{ __html: 'Can block a day or a slot from a specific day' }}
                />
                <Margin margin={3} />
                <Text11
                  dangerouslySetInnerHTML={{ __html: 'Date' }}
                />
                <Margin margin={1} />
                {console.log(inputs)}
                <DateInput
                  placeholder='Date'
                  name='date'
                  value={inputs.date}
                  onChange={handleInputChange}
                />
                <Margin margin={2} />
                <Text11
                  dangerouslySetInnerHTML={{ __html: 'Slot' }}
                />
                <Margin margin={1} />
                <Dropdown
                    placeholder='Slot'
                    name='slots'
                    classNamePrefix='selectBox'
                    options={slots}
                    onChange={slots => setInputs(inputs => ({ ...inputs, slot: slots.value }))}    
                    value={slots && slots.filter(data => (moment(data.label,"H:mm").format("HH:mm") === moment(inputs.slot,"H:mm").format("HH:mm"))) 
                      ? slots.filter(data => data.value === (inputs.slot)) 
                      : ''}  
                />
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center',marginTop: 20 }}>
                  <Toggle
                    onChange={() => setInputs(inputs => ({ ...inputs, visivel: !inputs.visivel }))}
                    offHandleColor="#EBEBEB"
                    onHandleColor="#FFCE20"
                    height={24}
                    checked={inputs.visivel}
                  />
                  <TextActive
                    color={'#373737' }
                  >
                    Show on calendar
                  </TextActive>
                </div>
              </Container>
              <Margin margin={5} />
              {typeContent === 'addBlock' &&
                <DivButtons>
                  <ButtonDelete onClick={() => closeModal()}>Cancel</ButtonDelete>
                  <ButtonEdit onClick={() => addRow()}>Save</ButtonEdit>
                </DivButtons>
              }
              {typeContent === 'editBlock' &&
                <DivButtons>
                  <ButtonDelete onClick={() => closeModal()}>Cancel</ButtonDelete>
                  <ButtonEdit onClick={() => editRow()}>Save</ButtonEdit>
                </DivButtons>
              }
            </Content>
            }
          {(typeContent === 'addEvent' || typeContent === 'editEvent') &&
            <Content>
              <Container>
                <Text14
                  dangerouslySetInnerHTML={{ __html: 'Can create an event on a specific date and time.' }}
                />
                <Margin margin={3} />
                <Text11
                  dangerouslySetInnerHTML={{ __html: 'Date' }}
                />
                <Margin margin={1} />
                {console.log(inputs)}
                <DateInput
                  placeholder='Date'
                  name='date'
                  value={inputs.date}
                  onChange={handleInputChange}
                />
                <Margin margin={2} />
                <Text11
                  dangerouslySetInnerHTML={{ __html: 'Time' }}
                />
                <Margin margin={1} />
                <TimeField
                  name = 'time'
                  value = {inputs.time}
                  onChange = {handleInputChange}   
                  style={{
                    width:'90px',
                    padding: '16px 24px',
                    border: '1px solid #EBEBEB',
                    borderRadius:'2px',
                    fontSize: '14px',
                    lineHeight: '16px',
                    color: '#373737',
                    boxSizing: 'border-box',                 
                  }}                 
                />                
              </Container>
              <Margin margin={5} />
              {typeContent === 'addEvent' &&
                <DivButtons>
                  <ButtonDelete onClick={() => closeModal()}>Cancel</ButtonDelete>
                  <ButtonEdit onClick={() => addRow()}>Save</ButtonEdit>
                </DivButtons>
              }
              {typeContent === 'editEvent' &&
                <DivButtons>
                  <ButtonDelete onClick={() => closeModal()}>Cancel</ButtonDelete>
                  <ButtonEdit onClick={() => editRow()}>Save</ButtonEdit>
                </DivButtons>
              }
            </Content>
          } 

          {console.log(inputs)}      

          {typeContent === 'rangeEvents' &&
            <Content>
              <Container>
                <Text14
                  dangerouslySetInnerHTML={{ __html: 'Create an event on a specific time and range of dates.' }}
                />
                <Margin margin={3} />
                <Text11
                  dangerouslySetInnerHTML={{ __html: 'Start Date' }}
                />
                <Margin margin={1} />
                <DateInput
                  placeholder='Start Date'
                  name='dataInicio'
                  value={inputs.dataInicio}
                  onChange={handleInputChange}
                />
                <Margin margin={2} />
                <Text11
                  dangerouslySetInnerHTML={{ __html: 'End Date' }}
                />
                <Margin margin={1} />
                <DateInput
                  placeholder='End Date'
                  name='dataFim'
                  value={inputs.dataFim}
                  onChange={handleInputChange}
                />
                <Margin margin={2} />                
                <Text11
                  dangerouslySetInnerHTML={{ __html: 'Time' }}
                />
                <Margin margin={1} />
                <TimeField
                  name = 'time'
                  value = {inputs.time}
                  onChange = {handleInputChange}   
                  style={{
                    width:'90px',
                    padding: '16px 24px',
                    border: '1px solid #EBEBEB',
                    borderRadius:'2px',
                    fontSize: '14px',
                    lineHeight: '16px',
                    color: '#373737',
                    boxSizing: 'border-box',                 
                  }}                 
                />                
              </Container>
              <Margin margin={5} />
              {typeContent === 'rangeEvents' &&
                <DivButtons>
                  <ButtonDelete onClick={() => closeModal()}>Cancel</ButtonDelete>
                  <ButtonEdit onClick={() => addRow()}>Save</ButtonEdit>
                </DivButtons>
              }
            </Content>
            }                       
            {(typeContent === 'addSlotLanguage' || typeContent === 'editSlotLanguage') &&
              <Content>
                <Container>
                  <Text14
                    dangerouslySetInnerHTML={{ __html: 'Can choose a specific language for a specific slot' }}
                  />
                  <Margin margin={3} />
                  <Text11
                    dangerouslySetInnerHTML={{ __html: 'Date' }}
                  />
                  <Margin margin={1} />

                  {console.log(inputs)}

                  <DateInput
                    placeholder='Date'
                    name='date'
                    value={inputs.date}
                    onChange={handleInputChange}
                  />
                  <Margin margin={2} />
                  <Text11
                    dangerouslySetInnerHTML={{ __html: 'Slot' }}
                  />
                  <Margin margin={1} />

                  {console.log(slots)}
                  {console.log(inputs)}
                  
                  <Dropdown
                    placeholder='Slot'
                    name='slots'
                    classNamePrefix='selectBox'
                    options={slots}
                    onChange={slots => setInputs(inputs => ({ ...inputs, slot: slots.value }))}    
                    value={slots && slots.filter(data => (moment(data.label,"H:mm").format("HH:mm") === moment(inputs.slot,"H:mm").format("HH:mm"))) 
                      ? slots.filter(data => (moment(data.value,"H:mm").format("HH:mm:ss") === moment(inputs.slot,"H:mm").format("HH:mm:ss"))) 
                      : ''}  
                  />
                  <Margin margin={2} />
                  <Text11
                    dangerouslySetInnerHTML={{ __html: 'Language' }}
                  />
                  <Margin margin={1} />

                  {console.log(languageOptions)}

                  <Dropdown
                    placeholder='Language'
                    name='Lingua'
                    classNamePrefix='selectBox'
                    options={languageOptions}
                    onChange={languageOptions => setInputs(inputs => ({ ...inputs, language: getLanguage(languageOptions.label)}))}
                    value={filterLanguages()}
                  />
                </Container>
                <Margin margin={5} />
                {typeContent === 'addSlotLanguage' &&
                  <DivButtons>
                    <ButtonDelete onClick={() => closeModal()}>Cancel</ButtonDelete>
                    <ButtonEdit onClick={() => addRow()}>Save</ButtonEdit>
                  </DivButtons>
                }
                {typeContent === 'editSlotLanguage' &&
                  <DivButtons>
                    <ButtonDelete onClick={() => closeModal()}>Cancel</ButtonDelete>
                    <ButtonEdit onClick={() => editRow()}>Save</ButtonEdit>
                  </DivButtons>
                }
              </Content>
            }          
            {(typeContent === 'bulkBlock') &&
              <Content>
                <Container>
                  <Text14
                    dangerouslySetInnerHTML={{ __html: 'Block a range of days' }}
                  />
                  <Margin margin={3} />
                  <Text11
                    dangerouslySetInnerHTML={{ __html: 'Start Date' }}
                  />
                  <Margin margin={1} />
                  <DateInput
                    placeholder='Date'
                    name='dataInicio'
                    value={inputs.dataInicio}
                    onChange={handleInputChange}
                  />
                  <Margin margin={2} />
                  <Text11
                    dangerouslySetInnerHTML={{ __html: 'End Date' }}
                  />
                  <Margin margin={1} />
                  <DateInput
                    placeholder='Date'
                    name='dataFim'
                    value={inputs.dataFim}
                    onChange={handleInputChange}
                  />
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center',marginTop: 20 }}>
                  <Toggle
                    onChange={() => setInputs(inputs => ({ ...inputs, visivel: !inputs.visivel }))}
                    offHandleColor="#EBEBEB"
                    onHandleColor="#FFCE20"
                    height={24}
                    checked={inputs.visivel}
                  />
                  <TextActive
                    color={'#373737' }
                  >
                    Show on calendar
                  </TextActive>
                </div>
                </Container>
                <Margin margin={5} />
                <DivButtons>
                  <ButtonDelete onClick={() => closeModal()}>Cancel</ButtonDelete>
                  <ButtonEdit onClick={() => addRow()}>Save</ButtonEdit>
                </DivButtons>
              </Content>
            }
            {(typeContent === 'addDiscount' || typeContent === 'editDiscount') &&
              <Content>
                <Container>
                  <Text11
                    dangerouslySetInnerHTML={{ __html: 'About' }}
                  />
                  <Margin margin={1} />
                  <Dropdown
                    placeholder='About'
                    name='about'
                    classNamePrefix='selectBox'
                    options={aboutDiscount}
                    onChange={aboutDiscount => setInputs(inputs => ({ ...inputs, about: aboutDiscount.label }))}
                    value={aboutDiscount.filter(data => (data.value == inputs.about)) ? aboutDiscount.filter(data => (data.value == inputs.about)) : ''}
                  />
                  <Margin margin={2} />
                  <Text11
                    dangerouslySetInnerHTML={{ __html: 'Comparator' }}
                  />
                  <Margin margin={1} />
                  <Dropdown
                    placeholder='Comparator'
                    name='comparator'
                    classNamePrefix='selectBox'
                    options={comparator}
                    onChange={comparator => setInputs(inputs => ({ ...inputs, comparator: comparator.label }))}
                    value={comparator && comparator.filter(data => (data.value === inputs.comparator)) ? comparator.filter(data => (data.value === inputs.comparator)) : ''}
                  />
                  <Margin margin={2} />
                  <Text11
                    dangerouslySetInnerHTML={{ __html: 'Number Tickets' }}
                  />
                  <Margin margin={1} />
                  <NumberInput
                    placeholder='Number Tickets'
                    name='value'
                    value={inputs.value}
                    onChange={handleInputChange}
                  />
                  <Margin margin={2} />
                  <Text11
                    dangerouslySetInnerHTML={{ __html: 'Value p/ticket' }}
                  />
                  <Margin margin={1} />
                  <NumberInput
                    placeholder='Value p/ticket'
                    name='discount'
                    value={inputs.discount}
                    onChange={handleInputChange}
                  />
                </Container>
                <Margin margin={5} />
                {typeContent === 'addDiscount' &&
                  <DivButtons>
                    <ButtonDelete onClick={() => closeModal()}>Cancel</ButtonDelete>
                    <ButtonEdit onClick={() => addRow()}>Save</ButtonEdit>
                  </DivButtons>
                }
                {typeContent === 'editDiscount' &&
                  <DivButtons>
                    <ButtonDelete onClick={() => closeModal()}>Cancel</ButtonDelete>
                    <ButtonEdit onClick={() => editRow()}>Save</ButtonEdit>
                  </DivButtons>
                }
              </Content>
            }
            {(typeContent === 'addDay' || typeContent === 'editDay') &&
              <Content>
                <Container>
                  <Text11
                    dangerouslySetInnerHTML={{ __html: 'Day Week' }}
                  />
                  <Margin margin={1} />
                  <Dropdown
                    placeholder='Day Week'
                    name='about'
                    classNamePrefix='selectBox'
                    value={dayWeek && dayWeek.filter(data => data.value == inputs.dayweek) ? dayWeek.filter(data => data.value == inputs.dayweek) : ''}
                    options={dayWeek}
                    onChange={dayWeek => setInputs(inputs => ({ ...inputs, dayweek: dayWeek.label }))}
                  />
                  <Margin margin={2} />
                  <Text11
                    dangerouslySetInnerHTML={{ __html: 'Start Time' }}
                  />
                  <Margin margin={1} />
                  <HourInput
                    placeholder='Start Time'
                    name='startHour'
                    value={inputs.startHour}
                    onChange={handleInputChange}
                  />
                  <Margin margin={2} />
                  <Text11
                    dangerouslySetInnerHTML={{ __html: 'End Time' }}
                  />
                  <Margin margin={1} />
                  <HourInput
                    placeholder='End Time'
                    name='endHour'
                    value={inputs.endHour}
                    onChange={handleInputChange}
                  />
                  <Margin margin={2} />
                  <Text11
                    dangerouslySetInnerHTML={{ __html: 'Tickets' }}
                  />
                  <Margin margin={1} />
                  <NumberInput
                    placeholder='Tickets'
                    name='tickets'
                    value={inputs.tickets}
                    onChange={handleInputChange}
                  />
                  <Margin margin={3} />
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Toggle
                      onChange={() => setInputs(inputs => ({ ...inputs, active: !inputs.active }))}
                      offHandleColor="#EBEBEB"
                      onHandleColor="#FFCE20"
                      height={24}
                      checked={inputs.active}
                    />
                    <TextActive
                      color={inputs.active ? '#373737' : '#EBEBEB'}
                    >
                      Active
                    </TextActive>
                  </div>                  
                </Container>
                <Margin margin={5} />
                {typeContent === 'addDay' &&
                  <DivButtons>
                    <ButtonDelete onClick={() => closeModal()}>Cancel</ButtonDelete>
                    <ButtonEdit onClick={() => addRow()}>Save</ButtonEdit>
                  </DivButtons>
                }
                {typeContent === 'editDay' &&
                  <DivButtons>
                    <ButtonDelete onClick={() => closeModal()}>Cancel</ButtonDelete>
                    <ButtonEdit onClick={() => editRow()}>Save</ButtonEdit>
                  </DivButtons>
                }
              </Content>
            }
            {(typeContent === 'bulkDay') &&
              <Content>
                <Container>
                  <Text11
                    dangerouslySetInnerHTML={{ __html: 'Start Time' }}
                  />
                  <Margin margin={1} />
                  <HourInput
                    placeholder='Start Time'
                    name='startHour'
                    value={inputs.startHour}
                    onChange={handleInputChange}
                  />
                  <Margin margin={2} />
                  <Text11
                    dangerouslySetInnerHTML={{ __html: 'End Time' }}
                  />
                  <Margin margin={1} />
                  <HourInput
                    placeholder='End Time'
                    name='endHour'
                    value={inputs.endHour}
                    onChange={handleInputChange}
                  />
                  <Margin margin={2} />
                  <Text11
                    dangerouslySetInnerHTML={{ __html: 'Tickets' }}
                  />
                  <Margin margin={1} />
                  <NumberInput
                    placeholder='Tickets'
                    name='tickets'
                    value={inputs.tickets}
                    onChange={handleInputChange}
                  />
                </Container>
                <Margin margin={5} />
                <DivButtons>
                  <ButtonDelete onClick={() => closeModal()}>Cancel</ButtonDelete>
                  <ButtonEdit onClick={() => addRow()}>Save</ButtonEdit>
                </DivButtons>
              </Content>
            }
            {(typeContent === 'addCoupon' || typeContent === 'editCoupon') &&
              <Content>
                <Container>
                  <Text11
                    dangerouslySetInnerHTML={{ __html: 'Name' }}
                  />
                  <Margin margin={1} />
                  <TextInput
                    placeholder='Name'
                    name='name'
                    value={inputs.name}
                    onChange={handleInputChange}
                  />
                  <Margin margin={2} />
                  <Text11
                    dangerouslySetInnerHTML={{ __html: 'Code' }}
                  />
                  <Margin margin={1} />
                  <TextInput
                    placeholder='Code'
                    name='code'
                    value={inputs.code}
                    onChange={handleInputChange}
                  />
                  <Margin margin={2} />
                  <Text11
                    dangerouslySetInnerHTML={{ __html: 'Value p/ticket' }}
                  />
                  <Margin margin={1} />
                  <NumberInput
                    placeholder='Discount'
                    name='discount'
                    value={inputs.discount}
                    onChange={handleInputChange}
                  />
                  <Margin margin={2} />
                  <Text11
                    dangerouslySetInnerHTML={{ __html: 'Start Date' }}
                  />
                  <Margin margin={1} />
                  <DateInput
                    placeholder='Start Date'
                    name='startDate'
                    value={inputs.startDate}
                    onChange={handleInputChange}
                  />
                  <Margin margin={2} />
                  <Text11
                    dangerouslySetInnerHTML={{ __html: 'End Date' }}
                  />
                  <Margin margin={1} />
                  <DateInput
                    placeholder='End Date'
                    name='endDate'
                    value={inputs.endDate}
                    onChange={handleInputChange}
                  />
                  <Margin margin={2} />
                  <Text11
                    dangerouslySetInnerHTML={{ __html: 'Utilizations' }}
                  />
                  <Margin margin={1} />
                  <NumberInput
                    placeholder='Utilizations'
                    name='utilizations'
                    value={inputs.utilizations}
                    onChange={handleInputChange}
                  />
                </Container>
                <Margin margin={5} />
                {typeContent === 'addCoupon' &&
                  <DivButtons>
                    <ButtonDelete onClick={() => closeModal()}>Cancel</ButtonDelete>
                    <ButtonEdit onClick={() => addRow()}>Save</ButtonEdit>
                  </DivButtons>
                }
                {typeContent === 'editCoupon' &&
                  <DivButtons>
                    <ButtonDelete onClick={() => closeModal()}>Cancel</ButtonDelete>
                    <ButtonEdit onClick={() => editRow()}>Save</ButtonEdit>
                  </DivButtons>
                }
              </Content>
            }
            {(typeContent === 'addReservation' || typeContent === 'editReservation') &&
              <Content>
                <Container>
                  <Text11
                    dangerouslySetInnerHTML={{ __html: 'Name' }}
                  />
                  <Margin margin={1} />
                  <TextInput
                    placeholder='Name'
                    name='name'
                    value={inputs.name}
                    onChange={handleInputChange}
                  />
                  <Margin margin={2} />
                  <Text11
                    dangerouslySetInnerHTML={{ __html: 'Email' }}
                  />
                  <Margin margin={1} />
                  <TextInput
                    placeholder='Email'
                    name='email'
                    value={inputs.email}
                    onChange={handleInputChange}
                  />
                  <Margin margin={2} />
                  <Text11
                    dangerouslySetInnerHTML={{ __html: 'Country' }}
                  />
                  <Margin margin={1} />

                  {/*console.log(countries)}
                  {console.log(inputs)*/}

                  <Dropdown
                    name='country'
                    defaultValue={{code: "351", label: "Portugal", value: "PT"}}
                    classNamePrefix='selectBox'
                    options={countries}
                    onChange={option => handleCountry(option)}
                  /> 
                  <Margin margin={2} />                  
                  <Text11
                    dangerouslySetInnerHTML={{ __html: 'Contact' }}
                  />
                  <Margin margin={1} />  
                  <TextInput
                    placeholder='Contact'
                    name='contact'
                    value={inputs.contact}
                    onChange={handleInputChange}
                  />                 
                  <Margin margin={2} />
                  <Text11
                    dangerouslySetInnerHTML={{ __html: 'VAT' }}
                  />
                  <Margin margin={1} />
                  <TextInput
                    placeholder='VAT'
                    name='vat'
                    value={inputs.vat}
                    onChange={handleInputChange}
                  />
                  <Margin margin={2} />
                  <Text11
                    dangerouslySetInnerHTML={{ __html: 'Obs' }}
                  />
                  <Margin margin={1} />
                  <TextInput
                    placeholder='Obs'
                    name='obs'
                    value={inputs.obs}
                    onChange={handleInputChange}
                  />                  
                  <Margin margin={2} />
                  <Text11
                    dangerouslySetInnerHTML={{ __html: 'Date' }}
                  />
                  <Margin margin={1} />
                  <DateInput
                    placeholder='Date'
                    name='date'
                    value={inputs.date}                    
                    onChange={handleInputChange}
                  />
                  <Margin margin={2} />
                  <Text11
                    dangerouslySetInnerHTML={{ __html: 'Service' }}
                  />
                  <Margin margin={1} />

                  {/*console.log(services)} 
                  {console.log(inputs)*/} 

                  <Dropdown
                    isDisabled = {typeContent === 'editReservation' ? true : false}
                    placeholder='Service'
                    name='servico'
                    classNamePrefix='selectBox'
                    options={dataService}
                    value={servicesOptions.filter(data => (data.value === inputs.servico)) ? servicesOptions.filter(data => (data.value === inputs.servico)) : ''}                    
                    onChange={handleServiceChange}
                  />
                  <Margin margin={2} />

                  {/*console.log(ticketOptions)}
                  {console.log(availableTickets)}
                  {console.log(inputs)}
                  {console.log(inputs.typesReservation)}
                  {console.log(multiTicket)*/}

                  {multiTicket == false &&
                    <>
                      {console.log('QUANTITY')}
                      <Margin margin={2} />
                      <Text11
                        dangerouslySetInnerHTML={{ __html: 'Quantity' }}
                      />
                      <Margin margin={1} />
                      <NumberInput
                        disabled = {typeContent === 'editReservation' && payments ? true : false}                      
                        placeholder='Quantity'
                        name='quantity'
                        value={inputs.quantity}
                        onChange={handleInputChange}
                      />
                    </>
                  }

                  {/*console.log(typeContent)}                  
                  {console.log(allServices)}
                  {console.log(availableTickets)*/}

                  {typeContent === 'addReservation' && multiTicket == true &&
                    availableTickets
                      .filter(type => type.tipoBilheteAtivo == true)
                      .map((type, index) => (
                        <div key = {index}>  

                          {/*console.log(index)*/}

                          <Text11
                            dangerouslySetInnerHTML={{ __html: getTicketType(type.tipoBilheteEN)}}
                          />
                          <Margin margin={1} />
                          <NumberInput
                            placeholder='Quantity'
                            name={'typesReservation'}
                            value={inputs.typesQuantities[index] != undefined 
                             ? inputs.typesQuantities[index].quantity 
                             : 0
                            }
                            onChange={(event) => handleTypeQuantityChange(event, index)}
                          />
                        </div>
                      ))
                  } 
                  {typeContent === 'editReservation' && multiTicket == true &&                  
                    availableTickets
                      .filter(type => type.tipoBilheteAtivo == true)
                      .map((type, index) => (
                        <div key = {index}>

                          {/*console.log(inputs.typesQuantities)}
                          {console.log(index)}
                          {console.log(type)*/}

                          <Text11
                            dangerouslySetInnerHTML={{ __html: getTicketType(type.tipoBilheteEN)}}
                          />
                          <Margin margin={1} />

                          {/*console.log(type.id)*/} 

                          <NumberInput
                            disabled = {typeContent === 'editReservation' && payments ? true : false}
                            placeholder='Quantity'
                            name={'typesReservation'}
                            value={inputs.typesQuantities[index] != undefined 
                                   ? inputs.typesQuantities[index].quantity 
                                   : 0}
                            onChange={(event) => handleTypeQuantityChange(event, index)}
                          />
                          {/*value={inputs.typesReservation[inputs.typesReservation.findIndex(resType => resType.tipo_bilhete == type.id)] != undefined 
                          ? inputs.typesReservation[inputs.typesReservation.findIndex(resType => resType.tipo_bilhete == type.id)].quantidadeTipo
                          : null}*/}                       
                        </div>
                      ))
                  }              
                  <Margin margin={2} />
                  <Text11
                    dangerouslySetInnerHTML={{ __html: 'Slot' }}
                  />
                  <Margin margin={1} />
                  
                  {console.log(inputs)}
                  {console.log(inputs.slot)}
                  {console.log(slots)}

                  <Dropdown
                    placeholder='Slot'
                    name='slots'
                    classNamePrefix='selectBox'
                    options={slots}
                    onChange={slots => setInputs(inputs => ({ ...inputs, slot: slots.value }))}    
                    value={slots && slots.filter(data => (moment(data.label,"H:mm").format("HH:mm") === moment(inputs.slot,"H:mm").format("HH:mm"))) 
                      ? slots.filter(data => (moment(data.value,"H:mm").format("HH:mm:ss") === moment(inputs.slot,"H:mm").format("HH:mm:ss"))) 
                      : ''}  
                  />
                  {typeContent === 'addReservation' &&
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center',marginTop: 20 }}>
                      <Toggle
                        onChange={() => setInputs(inputs => ({ ...inputs, sendInvoice: !inputs.sendInvoice }))}
                        offHandleColor="#EBEBEB"
                        onHandleColor="#FFCE20"
                        height={24}
                        checked={inputs.sendInvoice}
                      />
                      <TextActive
                        color={'#373737' }
                      >
                        Send Invoice
                      </TextActive>
                    </div>
                  }                  
                </Container>
                <Margin margin={5} />
                {console.log(inputs)}                
                {typeContent === 'addReservation' &&
                  <DivButtons>
                    <ButtonDelete onClick={() => closeModal()}>Cancel</ButtonDelete>
                    <ButtonEdit onClick={() => addRow()}>Save</ButtonEdit>
                  </DivButtons>
                }
                {typeContent === 'editReservation' &&
                  <DivButtons>
                    <ButtonDelete onClick={() => closeModal()}>Cancel</ButtonDelete>
                    <ButtonEdit onClick={() => editRow()}>Save</ButtonEdit>
                  </DivButtons>
                }
              </Content>
            }
            {(typeContent === 'addSeason' || typeContent === 'editSeason') &&
              <Content>
                <Container>
                  <Text11
                    dangerouslySetInnerHTML={{ __html: 'Title' }}
                  />
                  <Margin margin={1} />
                  <TextInput
                    placeholder='Title'
                    name='Text'
                    value={inputs.Text}
                    onChange={handleInputChange}
                  />
                  <Margin margin={2} />
                  <Text11
                    dangerouslySetInnerHTML={{ __html: 'Start Date' }}
                  />
                  <Margin margin={1} />
                  <DateInput
                    placeholder='Start Date'
                    name='DataInicio'
                    value={inputs.DataInicio}
                    onChange={handleInputChange}
                  />
                  <Margin margin={2} />
                  <Text11
                    dangerouslySetInnerHTML={{ __html: 'End Date' }}
                  />
                  <Margin margin={1} />
                  <DateInput
                    placeholder='End Date'
                    name='DataFim'
                    value={inputs.DataFim}
                    onChange={handleInputChange}
                  />
                  <Margin margin={2} />
                  <Text11
                    dangerouslySetInnerHTML={{ __html: 'Ticketing Options' }}
                  />

                  {/*console.log(inputs)}
                  {console.log(content)}
                  {console.log(ticketVarieties)}   
                  {console.log(inputs.ticketVariety)}      
                  {console.log(editedSeason)*/}

                  <Margin margin={1} />
                  <Dropdown
                    placeholder='Ticketing Options'
                    name='variedadeBilhetes'
                    classNamePrefix='selectBox'
                    options={ticketVarieties}
                    onChange={ticketVarieties => setInputs(inputs => ({ ...inputs, ticketVariety: ticketVarieties.value }))}
                    value={ticketVarieties && ticketVarieties.filter(data => (data.value === inputs.ticketVariety)) ? ticketVarieties.filter(data => (data.value == inputs.ticketVariety)) : ''}
                  />
                  <Margin margin={2} />
                  <Text11
                    dangerouslySetInnerHTML={{ __html: 'Ticket Price' }}
                  />
                  <Margin margin={1} />
                  <NumberInput
                    placeholder='Ticket Price'
                    name='PrecoUnitario'
                    step=".01"
                    value={inputs.PrecoUnitario}
                    onChange={handleInputChange}
                  />
                  <Margin margin={2} />
                  <Text11
                    dangerouslySetInnerHTML={{ __html: 'Discount Price' }}
                  />
                  <Margin margin={1} />
                  <NumberInput
                    placeholder='Discount Price'
                    name='DiscountPrice'
                    step=".01"
                    value={inputs.DiscountPrice}
                    onChange={handleInputChange}
                  />
                  <Margin margin={2} />
                  <Text11
                    dangerouslySetInnerHTML={{ __html: 'Discount Percentage' }}
                  />
                  <Margin margin={1} />
                  <NumberInput
                    placeholder='Discount Percentage'
                    name='DiscountPercentage'
                    step=".01"
                    value={inputs.DiscountPercentage}
                    onChange={handleInputChange}
                  /> 
                  <Margin margin={2} />
                  <Text11
                    dangerouslySetInnerHTML={{ __html: 'Discount Quantity' }}
                  />
                  <Margin margin={1} />
                  <NumberInput
                    placeholder='Discount Quantity'
                    name='DiscountQuantity'
                    step=".01"
                    value={inputs.DiscountQuantity}
                    onChange={handleInputChange}
                  />                                    
                  <Margin margin={2} />                  
                  <Text11
                    dangerouslySetInnerHTML={{ __html: 'Language' }}
                  />
                  <Margin margin={1} />
                  <Dropdown
                    placeholder='Language'
                    name='Lingua'
                    classNamePrefix='selectBox'
                    options={languageOptions}
                    onChange={languageOptions => setInputs(inputs => ({ ...inputs, language: getLanguage(languageOptions.label)}))}
                    value={filterLanguages()}
                  />
                </Container>
                <Margin margin={5} />
                {typeContent === 'addSeason' &&
                  <DivButtons>
                    <ButtonDelete onClick={() => closeModal()}>Cancel</ButtonDelete>
                    <ButtonEdit onClick={() => addRow()}>Save</ButtonEdit>
                  </DivButtons>
                }
                {typeContent === 'editSeason' &&
                  <DivButtons>
                    <ButtonDelete onClick={() => closeModal()}>Cancel</ButtonDelete>
                    <ButtonEdit onClick={() => editRow()}>Save</ButtonEdit>
                  </DivButtons>
                }
              </Content>
            }
            {typeContent === 'editTicketOffice' &&
              <Content>
                <Container>
                  <Text11
                    dangerouslySetInnerHTML={{ __html: 'Title' }}
                  />
                  <Margin margin={1} />
                  <TextInput
                    placeholder='Title'
                    name='title'
                    value={inputs.title}
                    onChange={handleInputChange}
                  />             
                  <Margin margin={2} />                                     
                  <Text11
                    dangerouslySetInnerHTML={{ __html: 'Description' }}
                  />
                  <Margin margin={1} />
                  <TextAreaInput
                    placeholder='Description'
                    name='description'
                    value={inputs.description}
                    maxLength={250}
                    onChange={handleInputChange}
                  />
                  <Margin margin={2} />                                                      
                  <Text11
                    dangerouslySetInnerHTML={{ __html: 'Url' }}
                  />
                  <Margin margin={1} />
                  <TextInput
                    placeholder='Url'
                    name='url'
                    value={inputs.url}
                    onChange={handleInputChange}
                  />
                  <Margin margin={2} />
                  <Text11
                    dangerouslySetInnerHTML={{ __html: 'Email' }}
                  />
                  <Margin margin={1} />
                  <TextInput
                    placeholder='Email'
                    name='email'
                    value={inputs.email}
                    onChange={handleInputChange}
                  />
                  {/*<Margin margin={2} />
                  <Text11
                    dangerouslySetInnerHTML={{ __html: 'Payments' }}
                  />
                  <Margin margin={1} />
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Toggle
                      onChange={() => setInputs(inputs => ({ ...inputs, Pagamentos: !inputs.Pagamentos }))}
                      offHandleColor="#EBEBEB"
                      onHandleColor="#FFCE20"
                      height={24}
                      checked={inputs.Pagamentos}
                    />
                    <TextActive
                      color={inputs.Pagamentos ? '#373737' : '#EBEBEB'}
                    >
                      Active
                    </TextActive>
                  </div>*/}
                  {/*<Margin margin={2} />
                  <Text11
                    dangerouslySetInnerHTML={{ __html: 'Stripe Key' }}
                  />
                  <Margin margin={1} />                  
                  <TextInput
                    placeholder='Stripe Api Key'
                    name='stripe_api_key'
                    value={inputs.stripe_api_key}
                    onChange={handleInputChange}
                  />*/}
                  <Margin margin={1} />
                </Container>
                <Margin margin={5} />
                <DivButtons>
                  <ButtonDelete onClick={() => closeModal()}>Cancel</ButtonDelete>
                  <ButtonEdit onClick={() => editRow()}>Save</ButtonEdit>
                </DivButtons>
              </Content>
            }
            {(typeContent === 'addLanguage' || typeContent === 'editLanguage') &&
              <Content>
                <Container>
                  <Text11
                    dangerouslySetInnerHTML={{ __html: 'Language Code' }}
                  />
                  <Margin margin={1} />

                  {/*console.log(inputs)}
                  {console.log(content)}
                  {console.log(languages)*/} 

                  <Dropdown
                    placeholder='Code'
                    name='Codigo'
                    classNamePrefix='selectBox'
                    options={availableLanguages}
                    onChange={availableLanguages => setInputs(inputs => ({ ...inputs, Codigo: availableLanguages.value }))}
                    value={availableLanguages && availableLanguages.filter(data => (data.value == inputs.Codigo)) ? availableLanguages.filter(data => (data.value == inputs.Codigo)) : ''}
                  />
                  <Margin margin={2} />                
                  <Text11
                    dangerouslySetInnerHTML={{ __html: 'Name Language EN' }}
                  />
                  <Margin margin={1} />
                  <TextInput
                    placeholder='Name Language EN'
                    name='nomeLingua_EN'
                    value={inputs.nomeLingua_EN}
                    onChange={handleInputChange}
                  />
                  <Margin margin={2} />
                  <Text11
                    dangerouslySetInnerHTML={{ __html: 'Name Language PT' }}
                  />
                  <Margin margin={1} />
                  <TextInput
                    placeholder='Name Language PT'
                    name='nomeLingua_PT'
                    value={inputs.nomeLingua_PT}
                    onChange={handleInputChange}
                  />
                  <Margin margin={2} />
                  <Text11
                    dangerouslySetInnerHTML={{ __html: 'Language Available' }}
                  />
                  <Margin margin={1} />
                  <Toggle
                    onChange={() => setInputs(inputs => ({ ...inputs, Lingua_Disponivel: !inputs.Lingua_Disponivel }))}
                    offHandleColor="#EBEBEB"
                    onHandleColor="#FFCE20"
                    height={24}
                    checked={inputs.Lingua_Disponivel}
                  />
                </Container>
                <Margin margin={5} />
                {typeContent === 'addLanguage' &&
                 <DivButtons>
                   <ButtonDelete onClick={() => closeModal()}>Cancel</ButtonDelete>
                   <ButtonEdit onClick={() => addRow()}>Save</ButtonEdit>
                 </DivButtons>
                }
                {typeContent === 'editLanguage' &&
                 <DivButtons>
                   <ButtonDelete onClick={() => closeModal()}>Cancel</ButtonDelete>
                   <ButtonEdit onClick={() => editRow()}>Save</ButtonEdit>
                 </DivButtons>
                }
              </Content>
            }
            {(typeContent === 'addTicketType' || typeContent === 'editTicketType') &&
              <Content>
                <Container>
                  <Text11
                    dangerouslySetInnerHTML={{ __html: 'Ticket Type PT' }}
                  />
                  <Margin margin={1} />
                  <TextInput
                    placeholder='Ticket Type PT'
                    name='ticketTypePT'
                    value={inputs.ticketTypePT}
                    onChange={handleInputChange}
                  />
                  <Margin margin={2} />
                  <Text11
                    dangerouslySetInnerHTML={{ __html: 'Ticket Type EN' }}
                  />
                  <Margin margin={1} />
                  <TextInput
                    placeholder='Ticket Type EN'
                    name='ticketTypeEN'
                    value={inputs.ticketTypeEN}
                    onChange={handleInputChange}
                  />
                  <Margin margin={2} />
                  <Text11
                    dangerouslySetInnerHTML={{ __html: 'Ticket Type FR' }}
                  />
                  <Margin margin={1} />
                  <TextInput
                    placeholder='Ticket Type FR'
                    name='ticketTypeFR'
                    value={inputs.ticketTypeFR}
                    onChange={handleInputChange}
                  />
                  <Margin margin={2} />
                  <Text11
                    dangerouslySetInnerHTML={{ __html: 'Ticket Price' }}
                  />
                  <Margin margin={1} />                  
                  <TextInput
                    placeholder='Ticket Price'
                    name='ticketPrice'
                    value={inputs.ticketPrice}
                    onChange={handleInputChange}
                  />
                  <Margin margin={2} />
                  <Text11
                    dangerouslySetInnerHTML={{ __html: 'Season' }}
                  />
                  <Margin margin={1} />  
                  {console.log(seasonOptions)}      
                  <Dropdown
                    placeholder='Season'
                    name='Season'
                    classNamePrefix='selectBox'
                    options={seasonOptions}
                    onChange={seasonOptions => setInputs(inputs => ({ ...inputs, season: getSeason(seasonOptions.value) }))}
                    value={filterSeasons()}
                  />
                  <Margin margin={2} />   
                  <Text11
                    dangerouslySetInnerHTML={{ __html: 'Ticket Active' }}
                  />
                  <Margin margin={1} />
                  <Toggle
                    onChange={() => setInputs(inputs => ({ ...inputs, activeTicketType: !inputs.activeTicketType }))}
                    offHandleColor="#EBEBEB"
                    onHandleColor="#FFCE20"
                    height={24}
                    checked={inputs.activeTicketType}
                  />                  
                </Container>
                <Margin margin={5} />                
                {typeContent === 'addTicketType' &&
                 <DivButtons>
                   <ButtonDelete onClick={() => closeModal()}>Cancel</ButtonDelete>
                   <ButtonEdit onClick={() => addRow()}>Save</ButtonEdit>
                 </DivButtons>
                }
                {typeContent === 'editTicketType' &&
                  <DivButtons>
                    <ButtonDelete onClick={() => closeModal()}>Cancel</ButtonDelete>
                    <ButtonEdit onClick={() => editRow()}>Save</ButtonEdit>
                  </DivButtons>
                }
              </Content>
            }              
            {(typeContent === 'addService' || typeContent === 'editService') &&
              <Content>
                {console.log(idBilheteira)}
                <Container>
                  <Text14
                    dangerouslySetInnerHTML={{ __html: 'Url Domain' }}
                  />
                  <Margin margin={1} />
                    {typeContent === 'addService' && 
                      <TextInput
                        placeholder='Url Domain'
                        name='urlDomain'
                        value={inputs.urlDomain}
                        onChange={handleInputChange}
                      />                    
                    }
                    {typeContent === 'editService' &&
                      <TextInput
                        disabled
                        placeholder='Url Domain'
                        name='urlDomain'
                        value={inputs.urlDomain}
                        onChange={handleInputChange}
                      />  
                    }
                  <Margin margin={3} />                
                  <Text14
                    dangerouslySetInnerHTML={{ __html: 'Url Path' }}
                  />
                  <Margin margin={1} />
                    {typeContent === 'addService' && 
                      <TextInput
                        placeholder='Url Path'
                        name='urlPath'
                        value={inputs.urlPath}
                        onChange={handleInputChange}
                      />                    
                    }
                    {typeContent === 'editService' &&
                      <TextInput
                        disabled
                        placeholder='Url Path'
                        name='urlPath'
                        value={inputs.urlPath}
                        onChange={handleInputChange}
                      />   
                    }
                  <Margin margin={3} />
                  <Text14
                    dangerouslySetInnerHTML={{ __html: 'Url Information' }}
                  />
                  <Margin margin={1} />
                  <TextInput
                    placeholder='Url Information'
                    name='urlInfo'
                    value={inputs.urlInfo}
                    onChange={handleInputChange}
                  />                                      
                  <Margin margin={3} />                     
                  <Text14
                    dangerouslySetInnerHTML={{ __html: 'Service PT' }}
                  />
                  <Margin margin={1} />
                  <TextInput
                    placeholder='Service Name'
                    name='serviceName'
                    value={inputs.serviceName}
                    maxLength={65}                                        
                    onChange={handleInputChange}
                  />
                  <Margin margin={3} />                    
                  <Text14
                    dangerouslySetInnerHTML={{ __html: 'Service EN' }}
                  />
                  <Margin margin={1} />
                  <TextInput
                    placeholder='Service Name EN'
                    name='serviceNameEN'
                    value={inputs.serviceNameEN}
                    maxLength={65}                                        
                    onChange={handleInputChange}
                  />
                  <Margin margin={3} />                    
                  <Text14
                    dangerouslySetInnerHTML={{ __html: 'Service FR' }}
                  />
                  <Margin margin={1} />
                  <TextInput
                    placeholder='Service Name FR'
                    name='serviceNameFR'
                    value={inputs.serviceNameFR}
                    maxLength={65}                    
                    onChange={handleInputChange}
                  /> 
                  <Margin margin={3} />  
                  <Text14
                    dangerouslySetInnerHTML = {{ __html: 'Description PT' }}
                  />
                  <Margin margin = {1} />      
                  <TextAreaInput
                    placeholder ='Description'
                    name = 'description'
                    value = {inputs.description}
                    maxLength={250}
                    onChange = {handleInputChange}                    
                  />
                  <Margin margin={3} />  
                  <Text14
                    dangerouslySetInnerHTML = {{ __html: 'Description EN' }}
                  />
                  <Margin margin = {1} />      
                  <TextAreaInput
                    placeholder ='DescriptionEN'
                    name = 'descriptionEN'
                    value = {inputs.descriptionEN}
                    maxLength={250}
                    onChange = {handleInputChange}
                  />  
                  <Margin margin={3} />  
                  <Text14
                    dangerouslySetInnerHTML = {{ __html: 'Description FR' }}
                  />
                  <Margin margin = {1} />      
                  <TextAreaInput
                    placeholder ='Description FR'
                    name = 'descriptionFR'
                    value = {inputs.descriptionFR}
                    maxLength={250}
                    onChange = {handleInputChange}
                  /> 
                  <Margin margin={3} />  
                  <Text14
                    dangerouslySetInnerHTML = {{ __html: 'Checkin Text PT' }}
                  />
                  <Margin margin = {1} />      
                  <TextAreaInput
                    placeholder ='Checkin Text PT'
                    name = 'checkinTextPT'
                    value = {inputs.checkinTextPT}
                    maxLength={250}
                    onChange = {handleInputChange}
                  />
                  <Margin margin={3} />  
                  <Text14
                    dangerouslySetInnerHTML = {{ __html: 'Checkin Text EN' }}
                  />
                  <Margin margin = {1} />      
                  <TextAreaInput
                    placeholder ='Checkin Text EN'
                    name = 'checkinTextEN'
                    value = {inputs.checkinTextEN}
                    maxLength={250}
                    onChange = {handleInputChange}
                  />  
                  <Margin margin={3} />  
                  <Text14
                    dangerouslySetInnerHTML = {{ __html: 'Checkin Text FR' }}
                  />
                  <Margin margin = {1} />      
                  <TextAreaInput
                    placeholder ='Checkin Text FR'
                    name = 'checkinTextFR'
                    value = {inputs.checkinTextFR}
                    maxLength={250}
                    onChange = {handleInputChange}
                  />       
                  <Margin margin={3} />  
                  <Text14
                    dangerouslySetInnerHTML = {{ __html: 'Checkout Text PT' }}
                  />
                  <Margin margin = {1} />      
                  <TextAreaInput
                    placeholder ='Checkout Text PT'
                    name = 'checkoutTextPT'
                    value = {inputs.checkoutTextPT}
                    maxLength={250}
                    onChange = {handleInputChange}
                  />
                  <Margin margin={3} />  
                  <Text14
                    dangerouslySetInnerHTML = {{ __html: 'Checkout Text EN' }}
                  />
                  <Margin margin = {1} />      
                  <TextAreaInput
                    placeholder ='Checkout Text EN'
                    name = 'checkoutTextEN'
                    value = {inputs.checkoutTextEN}
                    maxLength={250}
                    onChange = {handleInputChange}
                  />  
                  <Margin margin={3} />  
                  <Text14
                    dangerouslySetInnerHTML = {{ __html: 'Checkout Text FR' }}
                  />
                  <Margin margin = {1} />      
                  <TextAreaInput
                    placeholder ='Checkout Text FR'
                    name = 'checkoutTextFR'
                    value = {inputs.checkoutTextFR}
                    maxLength={250}
                    onChange = {handleInputChange}
                  />                                                                                                                                                                       
                  <Margin margin={3} />                                                    
                  {/*<Text11
                    dangerouslySetInnerHTML={{ __html: 'Service Image' }}
                  />

                  {console.log(inputs.serviceImage)}

                  <Margin margin={1} /> 
                    <FileUpload
                      token = {token}
                      fileType = 'Image'
                      serviceId = {content.id}
                      uploadedFile = {inputs.serviceImage}
                      BACKEND_DOMAIN = {BACKEND_DOMAIN}                      
                    />                  
                  <Margin margin={2} />*/}
                  <Text14>
                    Booking Time Limit
                  </Text14>
                  <Margin margin={1} /> 
                  <Text11
                    dangerouslySetInnerHTML={{ __html: 'General Settings Override' }}
                  />
                  <Margin margin={1} />                   
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Toggle
                      offHandleColor="#EBEBEB"
                      onHandleColor="#FFCE20"
                      height={24}
                      name='timeLimitOverride'
                      checked={inputs.timeLimitOverride}
                      onChange={() => setInputs(inputs => ({ ...inputs, timeLimitOverride: !inputs.timeLimitOverride }))}                    
                    />
                    <TextActive
                      color={inputs.cupons ? '#373737' : '#EBEBEB'}
                    >
                      Active
                    </TextActive>
                  </div>                     
                  <Margin margin={1} />                        
                  <Text11
                    dangerouslySetInnerHTML={{ __html: 'Time In Advance Type' }}
                  />
                  <Margin margin={1} /> 
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Toggle
                      offHandleColor="#EBEBEB"
                      onHandleColor="#FFCE20"
                      height={24}
                      name='timeAdvanceType'
                      checked={inputs.timeAdvanceType}
                      onChange={() => setInputs(inputs => ({ ...inputs, timeAdvanceType: !inputs.timeAdvanceType }))}                    
                    />
                    <TextActive
                      color={inputs.cupons ? '#373737' : '#EBEBEB'}
                    >
                      Active
                    </TextActive>
                  </div>
                  <Margin margin={1} />                                               
                  <div style={{ display: 'flex', alignItems: 'center', gap: '48px' }}>
                    {/* Deadline Same Day Section */}
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'left', gap: '8px' }}>
                      <Text11
                        dangerouslySetInnerHTML={{ __html: 'Deadline Same Day' }}
                      />     
                      <TimeField
                        name='limitHour'
                        value={inputs.limitHour}
                        onChange={handleInputChange}
                        style={{
                          width:'90px',
                          padding: '16px 24px',
                          border: '1px solid #EBEBEB',
                          borderRadius:'2px',
                          fontSize: '14px',
                          lineHeight: '16px',
                          color: '#373737',
                          boxSizing: 'border-box',                 
                        }}                      
                      />
                    </div>
                    {/* Time In Advance Section */}
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'left', gap: '8px' }}>
                      <Text11
                        dangerouslySetInnerHTML={{ __html: 'Time In Advance (in Hours)' }}
                      />  
                      <Input
                        type='number'                  
                        name = 'timeAdvance'
                        value={inputs.timeAdvance}
                        onChange={handleInputChange}
                        style={{
                          width:'90px',
                          padding: '16px 16px 16px 24px',
                          border: '1px solid #EBEBEB',
                          borderRadius:'2px',
                          fontSize: '14px',
                          lineHeight: '16px',
                          color: '#373737',
                          boxSizing: 'border-box',                 
                        }}                      
                      /> 
                    </div>
                  </div>
                  <Margin margin={3} />                 
                  <Text14
                    dangerouslySetInnerHTML = {{ __html: 'Service Backup Email' }}
                  />
                  <Margin margin = {1} />      
                  <TextInput
                    placeholder ='Email'
                    name = 'backupEmailService'
                    value = {inputs.backupEmailService}
                    onChange = {handleInputChange}
                  />                                                                                                                                                                       
                  <Margin margin={3} />  
                  <Text14
                    dangerouslySetInnerHTML={{ __html: 'Service Status' }}
                  />
                  <Margin margin={1} />                   
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}> 
                    <Toggle
                      onChange={() => setInputs(inputs => ({ ...inputs, activeService: !inputs.activeService }))}
                      offHandleColor="#EBEBEB"
                      onHandleColor="#FFCE20"
                      height={24}
                      checked={inputs.activeService}
                    />
                    <TextActive
                      color={inputs.activeService ? '#373737' : '#EBEBEB'}
                    >
                      Active
                    </TextActive> 
                  </div>
                  <Margin margin={3} />
                  <Text14
                    dangerouslySetInnerHTML={{ __html: 'Payments' }}
                  />       
                  <Margin margin = {1} />
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>                                                                                                           
                    <Toggle
                      onChange={() => setInputs(inputs => ({ ...inputs, payments: !inputs.payments }))}
                      offHandleColor="#EBEBEB"
                      onHandleColor="#FFCE20"
                      height={24}
                      checked={inputs.payments}
                    />
                    <TextActive
                      color={inputs.payments ? '#373737' : '#EBEBEB'}
                    >
                      Active
                    </TextActive>  
                  </div>
                  <Margin margin={3} />
                  <Text14
                    dangerouslySetInnerHTML={{ __html: 'Invoicing' }}
                  />                    
                  <Margin margin = {1} />
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>                                                                                                           
                    <Toggle
                      onChange={() => setInputs(inputs => ({ ...inputs, invoicing: !inputs.invoicing }))}
                      offHandleColor="#EBEBEB"
                      onHandleColor="#FFCE20"
                      height={24}
                      checked={inputs.invoicing}
                    />
                    <TextActive
                      color={inputs.invoicing ? '#373737' : '#EBEBEB'}
                    >
                      Active
                    </TextActive>  
                  </div>                                                                                    
                </Container>
                <Margin margin={5} />                
                {typeContent === 'addService' &&
                 <DivButtons>
                   <ButtonDelete onClick={() => closeModal()}>Cancel</ButtonDelete>
                   <ButtonEdit onClick={() => addRow()}>Save</ButtonEdit>
                 </DivButtons>
                }
                {typeContent === 'editService' &&
                  <DivButtons>
                    <ButtonDelete onClick={() => closeModal()}>Cancel</ButtonDelete>
                    <ButtonEdit onClick={() => editRow()}>Save</ButtonEdit>
                  </DivButtons>
                } 
              </Content>
            }
            {(typeContent === 'addUser' || typeContent === 'editUser') &&
              <Content>
                <Container>
                  <Text11
                    dangerouslySetInnerHTML={{ __html: 'Username' }}
                  />
                  <Margin margin={1} />     
                  <TextInput
                    placeholder='Username'
                    name='userName'
                    value={inputs.userName}
                    onChange={handleInputChange}
                  />
                  {typeContent === 'addUser' && 
                    <>
                      <Margin margin={2} />                  
                      <Text11
                        dangerouslySetInnerHTML={{ __html: 'Password' }}
                      />
                      <Margin margin={1} />     
                        <TextInput
                          placeholder='Password'
                          name='password'
                          value={inputs.password}
                          onChange={handleInputChange}
                        />   
                    </>  
                  }             
                  <Margin margin={2} />                
                  <Text11
                    dangerouslySetInnerHTML={{ __html: 'Email' }}
                  />
                  <Margin margin={1} />
                  <TextInput
                    placeholder='Email'
                    name='email'
                    value={inputs.email}
                    onChange={handleInputChange}
                  />
                  <Margin margin={2} />
                  <Text11
                    dangerouslySetInnerHTML={{ __html: 'User Profile' }}
                  />
                  <Margin margin={1} />

                  {console.log(userProfile)}
                  {console.log(inputs)}  
                  {console.log(content)}                                    
                  {console.log(inputs.userProfile)} 

                  <Dropdown
                    isDisabled = {(typeContent === 'editUser' 
                      && (userProfile != 'Administrator' && content.user_profile.permissionsLevel == 'Manager'))
                        ? true : false}
                    placeholder='User Profile'
                    name='user_profile'
                    classNamePrefix='selectBox'
                    options={profileOptions}
                    onChange={profileOptions => setInputs(inputs => ({
                      ...inputs, userProfile: getProfile(profileOptions.value) 
                    }))}
                    value={filterProfiles()}
                  />                                        
                  {inputs.userProfile && 
                    (typeContent == 'addUser' ||
                      (typeContent == 'editUser' 
                        && ((userProfile == 'Administrator' && inputs.userProfile.permissionsLevel != 'Administrator') 
                          || (userProfile == 'Manager' && inputs.userProfile.permissionsLevel != 'Manager')
                        )
                      )
                    ) &&
                    <>
                      <Margin margin={2} />                     
                      <Text11
                        dangerouslySetInnerHTML={{ __html: 'Services' }}
                      />
                      <Margin margin={1} />

                      {console.log(services)}                  
                      {console.log(selectedServices)}

                      {userServices.map(({id, ...srv}) => (
                        <label>
                          <InputCheckbox
                            key={id}
                            type='checkbox'
                            name={srv.nome}                       
                            onChange={handleServiceCheck}
                            checked={selectedServices.map(service => {
                              {console.log(service.nome)}
                              {console.log(srv.label)}   

                              return service.nome == srv.nome
                            }).reduce((a,b)=> (a || b), false)}
                            value={srv.nome}                           
                          />
                            {srv.nome}
                        </label>
                      ))}
                    </>
                  }                  
                  {inputs.userProfile 
                    && userProfile == 'Administrator'
                    && inputs.userProfile.permissionsLevel != null 
                    && inputs.userProfile.permissionsLevel != 'Administrator' 
                    && inputs.userProfile.permissionsLevel != 'Manager' &&                    
                      <>
                        <Margin margin={2} /> 
                        <Text11
                          dangerouslySetInnerHTML={{ __html: 'Team' }}
                        />
                        <Margin margin={1} />
                        <Dropdown
                          placeholder='Team'
                          name='team'
                          classNamePrefix='selectBox'
                          options={teamOptions}
                          onChange={teamOptions => setInputs(inputs => ({
                            ...inputs, team: getTeam(teamOptions.value) 
                          }))}
                          value={filterTeams()}
                        />
                      </> 
                  }                                                      
                  {userProfile == 'Administrator' && 
                    inputs.userProfile &&
                    inputs.userProfile.permissionsLevel == 'Manager' &&
                    <>
                      <Margin margin={2} />                       
                      <Text11
                        dangerouslySetInnerHTML={{ __html: 'Team Users' }}
                      />
                      <Margin margin={1} />

                      {console.log(selectedUsers)}
                      {console.log(inputs.userName)} 

                      {users.map(({id, ...usr}) => (
                        <>
                          {usr.user_profile.permissionsLevel != 'Manager' &&
                            usr.user_profile.permissionsLevel != 'Administrator' &&
                            usr.username != inputs.userName &&
                              <label>
                                <InputCheckbox
                                  key={id}
                                  type='checkbox'
                                  name={usr.username}                       
                                  onChange={handleTeamCheck}
                                  checked={selectedUsers
                                    ? selectedUsers.map(item => {
                                        {console.log(usr.team)}                               
                                        {console.log(item.username)}                                                                

                                        return (item.username == usr.username)
                                      }).reduce((a,b)=> (a || b), false)
                                    : false
                                  }
                                  value={usr.username}                           
                                />
                                {usr.username}
                              </label>
                          }
                        </>
                      ))}
                    </>
                  }                          
                  <Margin margin={2} />
                  <Text11
                    dangerouslySetInnerHTML={{ __html: 'Confirmed' }}
                  />
                  <Margin margin={1} />
                  <Toggle
                    onChange={() => setInputs(inputs => ({ ...inputs, confirmed: !inputs.confirmed }))}
                    offHandleColor="#EBEBEB"
                    onHandleColor="#FFCE20"
                    height={24}
                    checked={inputs.confirmed}
                  />
                  <Margin margin={2} />
                  <Text11
                    dangerouslySetInnerHTML={{ __html: 'Active' }}
                  />
                  <Margin margin={1} />
                  <Toggle
                    onChange={() => setInputs(inputs => ({ ...inputs, active: !inputs.active }))}
                    offHandleColor="#EBEBEB"
                    onHandleColor="#FFCE20"
                    height={24}
                    checked={inputs.active}
                  />                  
                </Container>
                <Margin margin={5} />
                {typeContent === 'addUser' &&
                 <DivButtons>
                   <ButtonDelete onClick={() => closeModal()}>Cancel</ButtonDelete>
                   <ButtonEdit onClick={() => addRow()}>Save</ButtonEdit>
                 </DivButtons>
                }
                {typeContent === 'editUser' &&
                 <DivButtons>
                   <ButtonDelete onClick={() => closeModal()}>Cancel</ButtonDelete>
                   <ButtonEdit onClick={() => editRow()}>Save</ButtonEdit>
                 </DivButtons>
                }
              </Content>
            }
            {(typeContent === 'deleteBlock' ||
              typeContent === 'deleteEvent' ||              
              typeContent === 'deleteDiscount' || 
              typeContent === 'deleteDay' || 
              typeContent === 'deleteSeason' || 
              typeContent === 'deleteCoupon' || 
              typeContent === 'deleteLanguage' || 
              typeContent === 'deleteSlotLanguage' ||
              typeContent === 'deleteTicketType' ||
              typeContent === 'deleteService' ||
              typeContent === 'deleteUser') &&
              <Content>
                <Container>
                  <H5> Delete </H5>
                  <Margin margin={4} />
                  {console.log(content)}
                  <Text14 font='SFRegular' style={{ fontWeight: 'normal' }}>
                    Are you sure you want to delete <b>'{
                      content.username ||
                      content.Text ||
                      content.nome ||                       
                      content.diaSemana || 
                      content.nomeLingua_EN || 
                      content.tipoBilheteEN || 
                      (content.data && content.hora ? (content.data + ' ' + content.hora)
                        : (content.data && content.slot == null) ? (content.data)
                        : (content.data && content.slot) ? (content.data + ' ' + content.slot)
                        : (content.Data && content.slot) ? (content.Data + ' ' + content.slot) 
                        : content.Sobre ? (content.Sobre + ' ' + content.comparador + ' ' + content.valor) 
                        : '')                  
                    }'</b>?
                  </Text14>
                </Container>
                <Margin margin={5} />
                <DivButtons>
                  <ButtonDelete onClick={toogleModal}>Cancel</ButtonDelete> 
                  <ButtonEdit onClick={() => deleteRow()}>Delete</ButtonEdit>
                </DivButtons>
              </Content>
            }
            {(typeContent === 'deleteReservation') &&
              <Content>
                <Container>
                  <H5> Delete </H5>
                  <Margin margin={4} />
                  <Text14 font='SFRegular' style={{ fontWeight: 'normal' }}>
                    Are you sure you want to delete the reservation of <b>'{content.nome}'</b>?
                  </Text14>
                </Container>
                <Margin margin={5} />
                <DivButtons>
                  <ButtonDelete onClick={toogleModal}>Cancel</ButtonDelete>
                  <ButtonEdit onClick={() => deleteRow()}>Delete</ButtonEdit>
                </DivButtons>
              </Content>
            }
          </>
        }
      {submit === true &&
        <Content>
          <Container>
            {(loading === true) &&
              <DivNotification>
                <Text14
                  dangerouslySetInnerHTML={{ __html: 'Loading' }}
                />
              </DivNotification>
            }
            {(loading === false && error === true) &&
              <DivNotification>
                <Icon>
                  ❌
                </Icon>
                <Text14
                  dangerouslySetInnerHTML={{ __html: errorMessage }}
                />
              </DivNotification>
            }
            {(loading === false && success === true) &&
              <DivNotification>
                <Icon>
                  ✅
                </Icon>
                <Text14
                  dangerouslySetInnerHTML={{ __html: 'Success' }}
                />
              </DivNotification>
            }
          </Container>
        </Content>
      }
      {isOpen && <Global />}
    </DivModal>
  )
}

const Global = createGlobalStyle`
 html, body {
   overflow: hidden;
 }
`
const DivModal = styled.div`
 background: rgba(78, 93, 107, 0.6);
 position: fixed;
 top: 0;
 right: 0;
 bottom: 0;
 left: 0;
 display: flex;
 align-items: center;
 justify-content: center;
 transition: all 0.5s ease;
 opacity:1;
 z-index:999;
 ${({ isOpen }) => !isOpen && css`
   opacity:0;
   z-index: -1;
 `}
`;
const Content = styled.div`
 background-color: #fff;
 box-sizing: border-box;
 padding: 32px 40px;
 display: flex;
 flex-flow: column;
 width: 372px;
 max-height: 95%;
 overflow-y: auto;
 @media screen and (max-width:570px){
   width:350px;
 }
`;
const Container = styled.div`
 display: flex;
 flex-flow: column;
`;
const DivButtons = styled.div`
 display: flex;
 flex-flow:row;
 width:100%;
`;
const ButtonEdit = styled.div`
 margin-left: 20px;
 background: #FFCE20;
 border-radius: 2px;
 padding: 8px 24px;
 display: flex;
 align-items: center;
 justify-content: center;
 font-family: SFRegular;
 font-weight: 500;
 font-size: 12px;
 line-height: 24px;
 color: #373737;
 transition: all 0.4s ease;
 width: calc(50% - 10px);
 :hover{
   background: #F8C100;
   cursor: pointer;
 }
`;
const ButtonDelete = styled.div`
 background: #EBEBEB;
 border-radius: 2px;
 padding: 8px 24px;
 display: flex;
 align-items: center;
 justify-content: center;
 font-family: SFRegular;
 font-weight: 500;
 font-size: 12px;
 line-height: 24px;
 color: #373737;
 transition: all 0.4s ease;
 width: calc(50% - 10px);
 :hover{
   background: #A9A9A9;
   cursor: pointer;
 }
`;
const Input = styled.input`
  font-family: SFMedium;
  margin-bottom:8px;
  padding: 16px 24px;
  border: 1px solid #EBEBEB;
  border-radius: 2px;
  width: ${({ width }) => width ? width : ''}px;
  max-width: ${({ maxWidth }) => maxWidth ? maxWidth + 'px' : '100%'};
  margin-bottom: ${({ marginBottom }) => marginBottom ? marginBottom : '0'}px;
  font-size: 14px;
  line-height: 16px;
  color: #373737;
  box-sizing:border-box;
  transition: all 0.5s ease;
  ::placeholder{
    transition: all 0.5s ease;
    color: #A9A9A9;
  }
  :focus{
    background: #F9F9F9;
    outline:none;
  }
  &.error{
    border-color:#E3000F;
  }
  ${({ blocked }) => blocked && css`
    pointer-events:none;
    ::placeholder{
      color: #EBEBEB;
    }
  `}
  ${({ enabled }) => enabled && css`
    color: #FFCE20;
    border: 1px solid #FFCE20;
    background-color:rgba(255, 206, 32, 0.3);;
    ::placeholder{
      color: #FFCE20;
    }
  `}
`;
const TextInput = styled.input.attrs(props => ({
 type: "text"
}))`
 font-family: SFMedium;
 padding: 16px 24px;
 border: 1px solid #EBEBEB;
 border-radius: 2px;
 width:100%;
 height:48px;
 font-size: 14px;
 line-height: 16px;
 color: #373737;
 box-sizing:border-box;
 transition: all 0.5s ease;
 ::placeholder{
   transition: all 0.5s ease;
   color: #A9A9A9;
 }
 :focus{
   background: #F9F9F9;
   outline:none;
 }
 &.error{
   border-color:#E3000F;
 }
`;
const TextAreaInput = styled.textarea`
 font-family: SFMedium;
 padding: 16px 24px;
 border: 1px solid #EBEBEB;
 border-radius: 2px;
 width:100%;
 height:72px;
 font-size: 14px;
 line-height: 16px;
 color: #373737;
 box-sizing:border-box;
 transition: all 0.5s ease;
 ::placeholder{
   transition: all 0.5s ease;
   color: #A9A9A9;
 }
 :focus{
   background: #F9F9F9;
   outline:none;
 }
 &.error{
   border-color:#E3000F;
 }
`;
const NumberInput = styled.input.attrs(props => ({
 type: "number"
}))`
 font-family: SFMedium;
 padding: 16px 24px;
 border: 1px solid #EBEBEB;
 border-radius: 2px;
 width:100%;
 height:48px;
 font-size: 14px;
 line-height: 16px;
 color: #373737;
 box-sizing:border-box;
 transition: all 0.5s ease;
 ::placeholder{
   transition: all 0.5s ease;
   color: #A9A9A9;
 }
 :focus{
   background: #F9F9F9;
   outline:none;
 }
 &.error{
   border-color:#E3000F;
 }
`;
const DateInput = styled.input.attrs(props => ({
 type: "date"
}))`
 font-family: SFMedium;
 padding: 16px 24px;
 border: 1px solid #EBEBEB;
 border-radius: 2px;
 width:100%;
 height:48px;
 font-size: 14px;
 line-height: 16px;
 color: #373737;
 box-sizing:border-box;
 transition: all 0.5s ease;
 ::placeholder{
   transition: all 0.5s ease;
   color: #A9A9A9;
 }
 :focus{
   background: #F9F9F9;
   outline:none;
 }
 &.error{
   border-color:#E3000F;
 }
`;
const HourInput = styled.input.attrs(props => ({
 type: "time"
}))`
 font-family: SFMedium;
 padding: 16px 24px;
 border: 1px solid #EBEBEB;
 border-radius: 2px;
 width:100%;
 height:48px;
 font-size: 14px;
 line-height: 16px;
 color: #373737;
 box-sizing:border-box;
 transition: all 0.5s ease;
 ::placeholder{
   transition: all 0.5s ease;
   color: #A9A9A9;
 }
 :focus{
   background: #F9F9F9;
   outline:none;
 }
 &.error{
   border-color:#E3000F;
 }
`;
const Dropdown = styled(Select)`
 width: ${({ width }) => width ? width : '100%'};
 font-family: SFMedium;
 div{
   border-color: #E4EBF0;
   min-height:36px;
 }
 .selectBox__control {
   font-style: normal;
   font-size: 14px;
   line-height: 16px;
   color: #4E5D6B;
   box-sizing: border-box;
   border: 1px solid #EBEBEB;
   border-radius: 2px;
   height:48px;
   .selectBox__value-container {
     border-radius: 0;
     align-items: center;
     padding:16px 24px;
     box-sizing: border-box;
     height: 48px;
     .selectBox__input {
       display: flex !important;
       align-items: center !important;
     }
     .selectBox__single-value {
       display: flex;
       align-items: center;
     }
     .selectBox__placeholder {
       display: flex;
       align-items: center;
       transition: all 0.5s ease;
       color: #A9A9A9;
     }
   }
   .selectBox__indicators {
     height:48px;
     width:48px;
     background: #F5F7F7;
     justify-content:center;
     .selectBox__indicator-separator {
       display: none;
     }
     .selectBox__dropdown-indicator {
       display:flex;
       align-items: center;
       svg {
         transition: all 400ms ease;
         transform: rotate(0);
       }
     }
     &:hover {
       background-color: #E4EBF0;    
     }
   }
   :hover{
     border-color:#EBEBEB;
   }
 }
 .selectBox__menu {
   border-radius: 0;
   margin-top: 4px;
   box-sizing: border-box;
   box-shadow: none;
   border: 1px solid #d8d8d8;
   z-index: 2;
   .selectBox__menu-list {
     background: #fff !important;
     margin:0;
     .selectBox__option {
       font-size: 14px;
       display: flex;
       align-items: center;
       &.selectBox__option--is-selected {
         background-color: #d8d8d8;
         color: #000;
       }
       &.selectBox__option--is-focused {
         background-color: #eaeaea;
         color: #000;
       }
       &:hover {
         cursor: pointer;
         background: #E4EBF0;
       }
     }
   }
 }
 .selectBox__control--is-focused {
   border-width: 1px;
   border-style: solid;
   border-color: #F5F7F7;
   box-shadow: none;
   &:hover {
     border-color: #F5F7F7;
   }
 }
 .selectBox__control--menu-is-open {
   .selectBox__indicators {
     .selectBox__dropdown-indicator {
       svg {
         transform: rotate(-180deg);
       }
     }
   }
 }
`;

const DivNotification = styled.div`
 display:flex;
 flex-flow:row;
 min-height:200px;
 align-items:center;
 justify-content:center;
`;
const Icon = styled.span`
 margin-right:8px;
`;

const Toggle = styled(Switch)`
 .react-switch-bg{
   background-color:#fff !important;
   box-sizing: border-box;
   border: 1px solid #EBEBEB;
 }
`;
const TextActive = styled(Text11)`
 margin-left: calc(4px + (10 - 4) * ((100vw - 375px) / (1920 - 375)));
 text-transform: uppercase;
`;
const InputCheckbox = styled(Input)`
  margin-bottom: 5px;
`;
